import { BaseModelData, CRUD, CrudModel } from '@mmx/shared'

import { CampaignNodeData } from './campaign-node.model'

export interface CampaignData extends BaseModelData {
  name: string
  description?: string
  status: Campaign.STATUS
  trigger: Campaign.TRIGGER
  reEnroll: boolean
  startDate?: string
  endDate?: string
  enrollmentCount?: number
  nodes?: CampaignNodeData[]
}

@CRUD({
  base: '/campaigns',
})
export class CampaignModel extends CrudModel {
  name: string
  description?: string
  status: Campaign.STATUS
  trigger: Campaign.TRIGGER
  reEnroll: boolean
  startDate?: string
  endDate?: string
  enrollmentCount?: number
  nodes?: CampaignNodeData[]

  get link(): string {
    return ['', 'settings', 'campaigns', this.id, 'info'].join('/')
  }

  constructor(data?: CampaignData) {
    super(data, true)
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      status: this.status,
      startDate: this.startDate,
      trigger: this.trigger,
      reEnroll: this.reEnroll,
      endDate: this.endDate,
      nodes: this.nodes,
    }
  }
}

export namespace Campaign {
  export enum STATUS {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    TEST = 'test',
  }

  export enum TRIGGER {
    APPOINTMENT_NEW = 'appointment.created',
    APPOINTMENT_RESCHEDULED = 'appointment.rescheduled',
    APPOINTMENT_CANCELLED = 'appointment.cancelled',
    APPOINTMENT_COMPLETED = 'appointment.completed',
    INTAKE_CONFIRMED = 'intake.confirmed',
  }

  export enum STATS_TYPE {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
  }
}
