import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core'
import { filter, Subject, takeUntil } from 'rxjs'

import { AppConfirmService } from './app-confirm.service'

@Directive({
  selector: '[appConfirmClick]',
  standalone: false,
})
export class ConfirmClickDirective implements OnDestroy {
  @Input()
  public message: string

  @Input()
  public confirmDisabled = false

  @Input()
  public html = false

  @Output()
  public confirm = new EventEmitter<void>()

  private onDestroy$ = new Subject<void>()

  constructor(
    private appConfirmSvc: AppConfirmService,
  ) { }

  public ngOnDestroy() {
    this.onDestroy$.next()
    this.onDestroy$.complete()
  }

  @HostListener('click')
  public onClick() {
    if (this.confirmDisabled) {
      this.confirm.emit()
      return
    }

    this.appConfirmSvc.confirm({
      message: this.message,
      html: this.html,
    })
      .pipe(
        takeUntil(this.onDestroy$),
        filter((confirm) => confirm),
      )
      .subscribe(() => this.confirm.emit())
  }
}
