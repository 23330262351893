import { SelectOption } from '@mmx/shared'

import { Appointment } from '../models/appointment.model'

export const IntakeStatuses: SelectOption<string>[] = [
  {
    value: Appointment.INTAKE_STATUS.NONE,
    viewValue: 'statuses.intakes.none',
  },
  {
    value: Appointment.INTAKE_STATUS.DELIVERED,
    viewValue: 'statuses.intakes.delivered',
  },
  {
    value: Appointment.INTAKE_STATUS.PENDING,
    viewValue: 'statuses.intakes.pending',
  },
  {
    value: Appointment.INTAKE_STATUS.SUBMITTED,
    viewValue: 'statuses.intakes.submitted',
  },
  {
    value: Appointment.INTAKE_STATUS.CONFIRMED,
    viewValue: 'statuses.intakes.confirmed',
  },
  {
    value: Appointment.INTAKE_STATUS.TECH_CONFIRMED,
    viewValue: 'statuses.intakes.tech-confirmed',
  },
  {
    value: Appointment.INTAKE_STATUS.TECH_REVIEW,
    viewValue: 'statuses.intakes.tech-review',
  },
  {
    value: Appointment.INTAKE_STATUS.SYNCING,
    viewValue: 'statuses.intakes.syncing',
  },
  {
    value: Appointment.INTAKE_STATUS.SYNCED,
    viewValue: 'statuses.intakes.synced',
  },
  {
    value: Appointment.INTAKE_STATUS.NOT_REQUIRED,
    viewValue: 'statuses.intakes.not-required',
  },
]