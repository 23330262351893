import { BaseModel, BaseModelData, Clinician, ClinicianData, ModelDate, Patient, TransactionMethod } from '@mmx/shared'
import moment from 'moment'

import { ITransactionDetails } from './transaction-details.model'

export interface TransactionData extends BaseModelData {
  invoiceId?: string
  referenceId: string
  patientId: string
  appointmentId: string
  clinicId: string
  clinicianId: string
  clinician?: ClinicianData
  createdAt: ModelDate
  status: Transaction.STATUS
  method: TransactionMethod
  type: Transaction.TYPE
  amount: number
  amountNet: number
  currency: string
  details: ITransactionDetails
  patient?: Patient
  dispute?: any
  disputed?: boolean
  parentTransaction?: TransactionData
  customFields?: Record<string, any>
}

export class TransactionModel extends BaseModel {
  invoiceId?: string
  referenceId: string
  patientId: string
  patient?: Patient
  appointmentId: string
  clinicId: string
  clinicianId: string
  clinician?: Clinician
  createdAt: moment.Moment
  status: Transaction.STATUS
  method: TransactionMethod
  type: Transaction.TYPE
  amount: number
  amountNet: number
  currency: string
  details: ITransactionDetails
  dispute?: any
  disputed?: boolean
  parentTransaction?: TransactionModel
  paymentProvider?: Transaction.PAYMENT_PROVIDER
  customFields?: Record<string, any>

  get captureCutoff(): moment.Moment | undefined {
    if (
      this.status === Transaction.STATUS.PENDING &&
      this.type === Transaction.TYPE.PAYMENT
    ) {
      // technically Stripe allows 7 days, but we set it down to 5 in case something goes wrong
      return this.createdAt.clone().add(5, 'days').endOf('day')
    }
  }

  constructor(data: TransactionData) {
    super(data, true)

    if (data) {
      this.invoiceId = data.invoiceId
      this.referenceId = data.referenceId
      this.patientId = data.patientId
      this.appointmentId = data.appointmentId
      this.clinicId = data.clinicId
      this.clinicianId = data.clinicianId
      if (data.clinician) {
        this.clinician = new Clinician(data.clinician)
      }
      this.createdAt = this.transformDate(data.createdAt)
      this.status = data.status
      this.method = data.method
      this.type = data.type
      this.amount = data.amount
      this.currency = data.currency
      this.details = data.details
      this.dispute = data.dispute
      this.disputed = data.disputed
    }

    if (data.patient) {
      this.patient = new Patient(data.patient)
    }

    if (data.parentTransaction) {
      this.parentTransaction = new TransactionModel(data.parentTransaction)

      if (this.parentTransaction.patient == null && this.patient != null) {
        this.parentTransaction.patient = this.patient
      }
    }

    this.customFields = data.customFields || {}
  }

  get appointmentUrl(): string {
    return `/patient/${this.patientId}/appointment/${this.appointmentId}`
  }

  get fullName(): string {
    return (
      this.details?.cardHolderName || this.patient?.fullName || '[Unknown Name]'
    )
  }

  get text() {
    return 'Transaction on ' + this.createdAt
  }

  get url(): string {
    if (this.patientId) {
      return `/patient/${this.patientId}/transaction/${this.id}`
    } else {
      return `/transactions/${this.id}`
    }
  }

  get statusDisplay(): string {
    let statusDisplay: string

    if (this.disputed) {
      return 'transactions.dispute'
    }

    switch (this.status) {
      case Transaction.STATUS.SUCCESS:
        statusDisplay = 'common.success'
        break
      case Transaction.STATUS.FAILED:
        statusDisplay = 'common.failed'
        break
      case Transaction.STATUS.PENDING:
        statusDisplay = 'common.pending'
        break
      case Transaction.STATUS.VOIDED:
        statusDisplay = 'common.voided'
        break
      default:
        statusDisplay = ''
        break
    }
    return statusDisplay
  }

  get methodDisplay(): string {
    let methodDisplay: string
    switch (this.method) {
      case TransactionMethod.CREDIT_CARD:
        methodDisplay = 'payments.charge.credit-card'
        break
      case TransactionMethod.CASH:
        methodDisplay = 'payments.charge.cash'
        break
      case TransactionMethod.CHECK:
        methodDisplay = 'payments.charge.check'
        break
      case TransactionMethod.APPLE_PAY:
        methodDisplay = 'Apple Pay'
        break
      case TransactionMethod.GOOGLE_PAY:
        methodDisplay = 'Google Pay'
        break
      case TransactionMethod.STAFF_CREDIT_CARD:
        methodDisplay = 'payments.charge.staff-credit-card'
        break
      case TransactionMethod.PATIENT_IVR:
        methodDisplay = 'payments.charge.patient-ivr'
        break
      default:
        methodDisplay = ''
        break
    }
    return methodDisplay
  }

  get typeDisplay(): string {
    let typeDisplay: string
    switch (this.type) {
      case Transaction.TYPE.PAYMENT:
        typeDisplay = 'common.payment'
        break
      case Transaction.TYPE.CAPTURE:
        typeDisplay = 'transactions.capture'
        break
      case Transaction.TYPE.REFUND:
        typeDisplay = 'transactions.refund'
        break
      case Transaction.TYPE.DISPUTE:
        typeDisplay = 'transactions.dispute'
        break
      default:
        typeDisplay = ''
        break
    }
    return typeDisplay
  }

  get reasonDisplay(): string {
    let reasonDisplay: string
    if (this.details) {
      switch (this.details.reason) {
        case Transaction.REASON.COPAY:
          reasonDisplay = 'Copay'
          break
        case Transaction.REASON.MEDICAL_RECORD_FEE:
          reasonDisplay = 'Medical Record Fee'
          break
        case Transaction.REASON.CUSTOM:
          reasonDisplay = 'Custom'
          break
        case Transaction.REASON.PAYMENT_PLAN_PAYMENT:
          reasonDisplay = 'Payment Plan Payment'
          break
        default:
          reasonDisplay = ''
          break
      }
    }

    return reasonDisplay
  }
}

export namespace Transaction {
  export enum STATUS {
    SUCCESS = 'S',
    FAILED = 'F',
    PENDING = 'P',
    VOIDED = 'V',
  }

  export enum TYPE {
    PAYMENT = 'P',
    REFUND = 'R',
    CAPTURE = 'C',
    DISPUTE = 'D',
  }

  export enum PAYMENT_PROVIDER {
    STRIPE = 'stripe',
    TILLED = 'tilled',
  }

  export enum REASON {
    COPAY = 'O',
    MEDICAL_RECORD_FEE = 'M',
    CUSTOM = 'C',
    PAYMENT_PLAN_PAYMENT = 'P',
  }
}
