import { AUTHORIZATION_STATUS, BaseModelData, CRUD, CrudModel, ModelDate, PatientUploadedFile } from '@mmx/shared'
import * as _ from 'lodash'
import moment from 'moment'

import { AvailityServiceReview } from './availity'
import { ProductData, ProductModel } from './product.model'

export type LimitationQuantityType = 'visit' | 'hour' | 'day' | 'dosage' | 'FL'

export interface Limitations {
  quantity: number
  quantityType: LimitationQuantityType
}

export enum OutdatedFlags {
  ConflictDates = 'ConflictDates',
  MissingProcedures = 'MissingProcedures',
  WrongFacility = 'WrongFacility',
}

export interface AuthorizationData extends BaseModelData {
  patientId: string
  startAt?: ModelDate
  endAt?: ModelDate
  referrerId?: string
  providerIds?: string[]
  productIds?: string[]
  procedureCodes?: string[]
  products?: ProductData[]
  appointmentIds?: string[]
  outdatedAppointmentIds?: string[]
  ignoredOutdatedIds?: string[]

  nextPullAt?: ModelDate
  outdatedFlags?: OutdatedFlags[]
}

@CRUD({
  base: '/authorizations',
})
export class AuthorizationModel extends CrudModel {
  id: string
  patientId: string
  appointmentIds: Set<string>
  outdatedAppointmentIds: Set<string>
  ignoredOutdatedIds: Set<string>
  insuranceId?: string
  carrierLookupId?: string
  carrierId?: string
  referrerId?: string
  providerIds?: string[]
  procedureCodes?: string[]
  productIds: string[]
  products: ProductModel[]
  productNames: string[]
  diagnosisCodes?: string[]
  number: string
  startAt?: moment.Moment
  endAt?: moment.Moment
  referenceNumber?: string
  note?: string
  // cannot be typed because the structure depends on the insurance carrier
  fullAuthorizationObject?: AvailityServiceReview.Review
  limitations?: Limitations
  status: AUTHORIZATION_STATUS
  updatedAt: moment.Moment
  createdAt: moment.Moment
  customFields?: Record<string, any>
  attachments?: PatientUploadedFile[]
  nextPullAt?: moment.Moment
  outdatedFlags?: OutdatedFlags[]

  constructor(data: AuthorizationData) {
    super(data, true)

    if (data.startAt) {
      this.startAt = this.transformDate(data.startAt, false)
    }
    if (data.endAt) {
      this.endAt = this.transformDate(data.endAt, false)
    }
    if (data.nextPullAt) {
      this.nextPullAt = this.transformDate(data.nextPullAt, false)
    }

    this.products = (data.products || []).map((product: ProductData) => new ProductModel(product))
    this.productIds = data.productIds ?? this.products.map(product => product.id)
    this.productNames = this.products.map((product: ProductModel) => product.name)
    this.procedureCodes = data.procedureCodes ?? this.fullAuthorizationObject?.procedures?.map(p => p.code)
    this.appointmentIds = new Set(data.appointmentIds ?? [])
    this.outdatedAppointmentIds = new Set(data.outdatedAppointmentIds ?? [])
    this.ignoredOutdatedIds = new Set(data.ignoredOutdatedIds ?? [])
  }

  get dateFrom(): string | undefined {
    return this.startAt?.format('YYYY-MM-DD')
  }

  get dateTo(): string | undefined {
    return this.endAt?.format('YYYY-MM-DD')
  }

  toJSON(): any {
    return _.extend(super.toJSON(), {
      patientId: this.patientId,
      appointmentIds: this.appointmentIds ? [...this.appointmentIds] : undefined,
      insuranceId: this.insuranceId,
      carrierLookupId: this.carrierLookupId,
      carrierId: this.carrierId,
      fullAuthorizationObject: this.fullAuthorizationObject,
      productIds: this.productIds,
      number: this.number,
      startAt: this.startAt,
      endAt: this.endAt,
      referenceNumber: this.referenceNumber,
      status: this.status,
      note: this.note,
      limitations: this.limitations,
    })
  }
}
