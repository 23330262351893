import {
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PagedParams } from '@mmx/shared'
import moment from 'moment'
import { Observable } from 'rxjs'
import { map as oMap } from 'rxjs/operators'

import { AppointmentData, AppointmentModel } from '../models'
import { AuthenticatedRestService } from './auth-rest.service'

export interface GetWaitListInput extends PagedParams {
  date?: string | Date | moment.Moment
  start?: string
  end?: string
  facility?: string
  resources?: string[]
  products?: string[]
  categories?: string[]
  waitList?: boolean
  extend?: string
}

@Injectable({
  providedIn: 'root',
})
export class WaitListService extends AuthenticatedRestService {
  getWaitList(
    filters: GetWaitListInput,
  ): Observable<AppointmentModel[]> {
    if (filters.date) {
      filters.date = moment(filters.date).toISOString()
    }

    const route = `/appointments/wait-list`
    const params = this.parameterize(filters)
    return this.get<AppointmentData[]>(route, params).pipe(
      oMap((response) => {
        return response.data.map((a) => new AppointmentModel(a))
      }),
    )
  }

  private parameterize(object: Record<string, any>): HttpParams {
    let httpParams = new HttpParams()
    Object.entries(object).forEach(([key, value]) => {
      if (value) {
        httpParams = httpParams.set(key, value)
      }
    })
    return httpParams
  }
}
