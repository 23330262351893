export enum ClinicModulesKeys {
  APPOINTMENT = 'appointment',
  APPOINTMENT_RECOMMENDATIONS = 'appointmentRecommendations',
  APPOINTMENT_REQUESTS = 'appointmentRequests',
  APPOINTMENT_WAIT_LIST = 'appointmentWaitList',
  AUTHORIZATION = 'authorization',
  BILLING = 'billing',
  CONFIRMATION = 'confirmation',
  DASHBOARD_ATB = 'dashboardAtb',
  ELIGIBILITY = 'eligibility', // appointment-specific E&B
  ESTIMATE = 'estimate',
  EXTENDED_ADDRESS = 'extendedAddress',
  FINANCIAL = 'financial',
  INTAKE = 'intake',
  INTERNAL_MESSAGING = 'internalMessaging',
  NOTES = 'notes',
  PAYMENT = 'payment',
  PRICER = 'pricer',
  PROCEDURES_ESTIMATE = 'proceduresEstimate',
  REFERRAL = 'referral',
  RESULTS = 'results',
  SURVEY = 'survey',
  TECH = 'tech',
  WORKLIST = 'worklist',
}

export type ClinicModules = {
  [moduleKey in ClinicModulesKeys]?: boolean
}
