import { BaseModelData, CRUD, CrudModel } from '@mmx/shared'

export interface CampaignEnrollmentData extends BaseModelData {
  clinicId: string;
  path: string;
  campaignPath: string;
  campaignId: string;
  patientId: string;
  appointmentId?: string;
  currentNodeId?: string;
  scheduledAt?: string;
  waitingForEvent?: string;
  nodeHistory?: Array<{ nodeId: string; timestamp: string }>;
  status: 'noop' | 'progress' | 'completed' | null;
}

@CRUD({
  base: '/campaign-enrollments',
})
export class CampaignEnrollmentModel extends CrudModel {
  clinicId: string
  path: string
  campaignPath: string
  campaignId: string
  patientId: string
  appointmentId?: string
  currentNodeId?: string
  scheduledAt?: string
  waitingForEvent?: string
  nodeHistory?: Array<{ nodeId: string; timestamp: string }>
  status: 'noop' | 'progress' | 'completed' | null

  constructor(data?: CampaignEnrollmentData) {
    super(data, true)
  }

  toJSON() {
    return {
      id: this.id,
      clinicId: this.clinicId,
      path: this.path,
      campaignPath: this.campaignPath,
      campaignId: this.campaignId,
      patientId: this.patientId,
      appointmentId: this.appointmentId,
      currentNodeId: this.currentNodeId,
      scheduledAt: this.scheduledAt,
      waitingForEvent: this.waitingForEvent,
      nodeHistory: this.nodeHistory,
      status: this.status,
    }
  }
}
