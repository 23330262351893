import { SelectOption } from '@mmx/shared'

import { Appointment } from '../models/appointment.model'

export const EligibilityStatuses: SelectOption<string>[] = [
  {
    value: Appointment.ELIGIBILITY_STATUS.NONE,
    viewValue: 'statuses.eligibilities.none',
  },
  {
    value: Appointment.ELIGIBILITY_STATUS.HARD_FAIL,
    viewValue: 'statuses.eligibilities.hard-fail',
  },
  {
    value: Appointment.ELIGIBILITY_STATUS.SOFT_FAIL,
    viewValue: 'statuses.eligibilities.soft-fail',
  },
  {
    value: Appointment.ELIGIBILITY_STATUS.INVALID,
    viewValue: 'statuses.eligibilities.invalid',
  },
  {
    value: Appointment.ELIGIBILITY_STATUS.INACTIVE,
    viewValue: 'statuses.eligibilities.inactive',
  },
  {
    value: Appointment.ELIGIBILITY_STATUS.ACTIVE,
    viewValue: 'statuses.eligibilities.active',
  },
  {
    value: Appointment.ELIGIBILITY_STATUS.AVOIDED,
    viewValue: 'statuses.eligibilities.avoided',
  },
]