import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PagedRestResponse } from '@mmx/shared'
import { map, of, tap } from 'rxjs'

import { PaginationParams } from '.'
import { PaginationCache } from './pagination-cache.service'

@Injectable()
export class DynamoPaginationInterceptor implements HttpInterceptor {
  constructor(private cacheService: PaginationCache) {}

  /*
  intercept pagination requests, with pageIndex and pageSize params, and store the results
  along with the 'startAt' for dynamoDB, in a cache.
  */
  intercept(req: HttpRequest<PagedRestResponse<any>>, next: HttpHandler) {
    if (!isPaginated(req)) {
      return next.handle(req)
    } else {
      /* if the request is for paginated data, create a new HttpRequest with the same url
         and the params that should be part of the cache key.
         eg. for a request to '/referrers?pageSize=10&pageIndex=3&total=100&foo=bar', the
         cache key should only contain the static params. In this case, '/referrers?foo=bar'
      */
      const paginationParams = new PaginationParams(req)
      const params = req.params
        .delete('pageSize')
        .delete('pageIndex')
        .delete('previousPageIndex')
        .delete('startAt')
        .delete('total')
        .delete('length')
      const strippedRequest = new HttpRequest('get', req.url, { params })
      if (req.headers.get('x-refresh')) {
        this.cacheService.bust(strippedRequest)
      }
      let clonedReq = req.clone({
        headers: req.headers.delete('x-refresh'),
      })
      const cachedResponse = this.cacheService.get(strippedRequest)
      // check cache and return a new HttpResponse if all data available
      if (cachedResponse?.hasData(paginationParams)) {
        const body = {
          data: cachedResponse.sliceData(paginationParams),
          total: cachedResponse.total,
        }
        return of(new HttpResponse({ body }))
      } else {
        // cache miss, so check for any saved index to pass to server as 'startAt' param
        if (cachedResponse?.nextIndex) {
          clonedReq = clonedReq.clone({
            params: req.params.append('startAt', cachedResponse?.nextIndex),
          })
        }
        // pass the request, with startAt param if known, to the server
        return next.handle(clonedReq).pipe(
          // this is now the response stream. The new data is added to the cache
          tap<HttpEvent<PagedRestResponse<any>>>((event) => {
            if (event instanceof HttpResponse) {
              this.cacheService.put(strippedRequest, event.body)
            }
          }),
          /* retrieve data from cache and replace the data field in the response body.
             Doing this for every response allows us to check for the last page of data
          */
          map<
            HttpEvent<PagedRestResponse<any>>,
            HttpEvent<PagedRestResponse<any>>
          >((event) => {
            if (event instanceof HttpResponse) {
              const cachedResponse = this.cacheService.get(strippedRequest)
              if (cachedResponse.hasData(paginationParams)) {
                event.body.data = cachedResponse.sliceData(paginationParams)
              }
            }
            return event
          }),
        )
      }
    }
  }
}

// check for pagination params in request
function isPaginated(req: HttpRequest<any>) {
  return (
    (req.method === 'GET' &&
      req.params.has('pageIndex') &&
      req.params.has('pageSize') &&
      (/\/referrers$/.test(req.url) ||
        /\/shoppable-services$/.test(req.url) ||
        /\/charge-description-master$/.test(req.url))) ||
    /\/enrollments$/.test(req.url)
  )
}
