
export interface StudyData {
  eid?: string
  ekey?: string
  dicomUID?: string
  products?: string[]
  productCategories?: string[]
  diagnosisCodes?: DiagnosisCode[]
  status?: STATUS
  statusDescription?: string
  startAt?: string
  endAt?: string
  type?: string
}

export enum DiagnosisCodingMethod {
  ICD9CM = 'ICD-9-CM',
  ICD10CM = 'ICD-10-CM',
}

export interface DiagnosisCode {
  method?: DiagnosisCodingMethod
  code?: string
  description?: string
}

export enum STATUS {
  OPEN = 'O',
  HOLD = 'H',
  CANCELLED = 'C',
  RESCHEDULE = 'R',
  ARRIVED = 'A',
  CHECKING_IN = 'CI',
  CHECKED_IN = 'W',
  IN_PROGRESS = 'I',
  ABORTED = 'B',
  COMPLETED = 'D',
  NO_SHOW = 'N',
}

