import { SelectOption } from '@mmx/shared'

import { Appointment } from '../models'

export const AppointmentTypes: SelectOption<Appointment.TYPE>[] = [
  {
    value: Appointment.TYPE.APPOINTMENT,
    viewValue: 'common.appointment',
  },
  {
    value: Appointment.TYPE.ORDER,
    viewValue: 'common.order',
  },
]
