import { Address, PatientNameSchema, PatientSexes } from '@mmx/shared'
import {
  CustomQuestionSchema,
  standardizePhone,
} from 'app/core'
import { each, extend, isFunction, isNil, remove, toPlainObject } from 'lodash'
import moment from 'moment-timezone'

export class PatientIntakeFormData {
  id: string
  // consents
  acceptedTerms: boolean
  consented: { [key: string]: boolean }
  patientId: string
  // appointment provided data
  appointmentId: string
  stage: string

  // guarantor
  hasGuarantor: boolean
  guarantorGivenName: string
  guarantorFamilyName: string
  guarantorRelationship: string
  guarantorPhoneNumber: string
  guarantorDOB: Date | moment.Moment

  // patient information
  name: PatientNameSchema
  givenName: string
  familyName: string

  address: Address
  email: string
  phoneNumber: string
  receiveSmsAlert = true

  // additional
  dob: Date | moment.Moment
  sex: PatientSexes

  // pcp
  hasPCP: boolean
  pcpName: string
  pcpPhoneNumber: string
  pcpOffice: string
  pcpAddress: Address

  // employer
  isEmployed: boolean
  employerName: string
  employerAddress: Address
  employerPhoneNumber: string

  // emergency-contact
  emergencyContactName: string
  emergencyContactPhoneNumber: string

  // insurance primary
  hasInsurance: boolean
  hasSameInsurance: string
  insuranceCarrier: string
  insuranceNumber: string
  isPrimaryOnInsurance: boolean
  insuranceHolderFirstName: string
  insuranceHolderLastName: string
  insuranceRelationship: string
  insuranceHolderDob: Date | moment.Moment
  allowUnknownInsurance: boolean

  // secondary primary
  hasSecondaryInsurance: boolean
  secondaryInsuranceCarrier: string
  secondaryInsuranceNumber: string
  isPrimaryOnSecondaryInsurance: boolean
  secondaryInsuranceHolderFirstName: string
  secondaryInsuranceHolderLastName: string
  secondaryInsuranceRelationship: string
  secondaryInsuranceHolderDob: Date | moment.Moment

  // insurance auto
  isAutoAccidentRelated: boolean
  autoInsuranceProvider: string
  autoPolicyNumber: string
  autoClaimNumber: string
  ssn: string
  dateOfAccident: Date | moment.Moment
  autoInsuranceAddress: Address
  autoInsurancePhoneNumber: string
  autoAdjusterName: string

  // workers comp
  isWorkersCompensationRelated: boolean
  dateOfWorkplaceAccident: Date | moment.Moment
  workersCompInsuranceProvider: string
  workAddress: Address
  workPhoneNumber: string
  workSupervisorName: string
  workClaimNumber: string
  workAdjustersName: string
  workAdjustersPhoneNumber: string

  // custom questions
  customQuestions?: CustomQuestionSchema[]

  recentlyModifiedSections: string[]
  recentlyUploadedFiles: string[]

  sections?: string[]

  confirmed?: { clinicianId?: string; date?: string }
  unsigned?: { clinicianId?: string; date?: string }

  saveCustomQuestion(question: CustomQuestionSchema, clinicianId: string) {
    let oldItem: CustomQuestionSchema
    if (!this.customQuestions) {
      this.customQuestions = []
    }

    // remove existing
    remove(this.customQuestions, (item) => {
      if (item.id === question.id) {
        oldItem = item
        return true
      }
    })

    if (!oldItem && question.value != null) {
      // New data
      question.clinicianId = clinicianId
    } else if (oldItem && oldItem.value !== question.value) {
      // Replace data
      if (!moment(oldItem.value, true)) {
        question.clinicianId = clinicianId
      } else if (
        moment(oldItem.value, true) &&
        !moment(oldItem.value).isSame(moment(question.value), 'day')
      ) {
        // Replace date field
        question.clinicianId = clinicianId
      }
    } else if (oldItem && oldItem.clinicianId) {
      // Answer already changed by clinician
      question.clinicianId = oldItem.clinicianId
    }
    // save custom question
    this.customQuestions.push(question)
  }

  transformData(data: any) {
    each(data, (value, key) => {
      // standardize phone fields
      if (key.toLowerCase().endsWith('phonenumber')) {
        data[key] = value ? standardizePhone(value) : null
      }

      // strip out empty addresses
      if (
        value instanceof Address &&
        isNil(value.address1) &&
        isNil(value.city)
      ) {
        data[key] = null
      }

      // standardize booleans
      if (value === 'true') {
        data[key] = true
      } else if (value === 'false') {
        data[key] = false
      }
    })

    return data
  }

  toJSON() {
    const data: any = toPlainObject(this)

    // strip away useless fields
    each(['id', 'clinicId'], (key) => {
      delete data[key]
    })

    this.transformData(data)

    // delete all functions and nil values from the object
    each(data, (value, key) => {
      if (isNil(value) || isFunction(value)) {
        delete data[key]
      }
    })

    return data
  }

  fromJson(json: any) {
    extend(this, json)

    if (json) {
      const dateFields = [
        'dob',
        'guarantorDOB',
        'insuranceHolderDob',
        'secondaryInsuranceHolderDob',
        'dateOfAccident',
        'dateOfWorkplaceAccident',
      ]
      for (const dateField of dateFields) {
        if (json[dateField]) {
          this[dateField] = moment.utc(json[dateField])
        }
      }
    }
  }
}
