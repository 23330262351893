import {
  APPOINTMENT_REQUEST_STATUS,
  AUTHORIZATION_STATUS,
  BaseModel,
  BaseModelData,
  Facility,
  FacilityData,
  ModelDate,
  Patient,
  PatientData,
  PatientInsurance,
  PatientInsuranceData,
  REFERRAL_STATUS,
  Resource,
  ResourceData,
  StudyData,
} from '@mmx/shared'
import {
  extend,
  filter,
  findLast,
  includes,
  isArrayLike,
  isObjectLike,
  map,
  orderBy,
  pull,
} from 'lodash'
import moment from 'moment-timezone'

import { Breadcrumb } from '../data/breadcrumb.data'
import { AppointmentEstimateSchema } from '../schemas/estimate.schema'
import { getFacilityAndLocalTime } from '../utils/facility-and-local-time'
import { Invoice, InvoiceData, InvoiceModel } from './invoice.model'
import { PortalRuleData, PortalRuleModel } from './portal'
import { ProductData, ProductModel } from './product.model'
import { ReferrerData, ReferrerModel } from './referrer.model'
import { TransactionData, TransactionModel } from './transaction.model'
import { WorkItemData } from './worklist-item.model'

export interface AppointmentData extends BaseModelData {
  eid?: string
  ekey?: string
  cancellationReason?: string
  authorizations?: any[]
  authorizationStatuses?: AUTHORIZATION_STATUS[]
  referrals?: any[]
  referralStatuses?: REFERRAL_STATUS[]
  /** @deprecated */
  eligibilityStatus?: Appointment.ELIGIBILITY_STATUS
  /** @deprecated */
  eligibilityId?: string
  eligibilityDetails?: Appointment.EligibilityDetails
  type?: Appointment.TYPE
  startAt?: ModelDate
  endAt?: ModelDate
  duration?: number
  scheduledAt?: ModelDate
  estimateId?: string
  estimate?: AppointmentEstimateSchema
  intakeStatus?: Appointment.INTAKE_STATUS
  intakeProgress?: number
  intakeId?: string
  location?: FacilityData
  locationId: string
  referrerId?: string
  referrer?: ReferrerData
  notes?: string
  request?: { id: string, status: APPOINTMENT_REQUEST_STATUS }
  waitList?: Appointment.WaitList
  orderingPhysician?: string
  patient?: PatientData
  patientId: string
  paymentStatus?: Appointment.PAYMENT_STATUS
  priority?: number
  products?: (string | number | ProductData)[]
  productCategories?: string[]
  invoices?: InvoiceData[]
  status?: Appointment.STATUS
  statusDescription?: string
  transactions?: (string | number | TransactionData)[]
  insurances?: PatientInsuranceData[]
  /** @deprecated */
  eligibilities?: { [key: string]: string }
  workItems?: WorkItemData[]
  resources?: (string | ResourceData | Resource)[]
  customFields?: Record<string, any>
  metadata?: Record<string, any>
  patientConfirmedAt?: ModelDate
  patientConfirmedBy?: string
  patientConfirmed?: boolean
  lastConfirmMsgSentAt?: ModelDate
  timezone?: string
  orderedBy?: string
  scheduledBy?: string
  surveyStatus?: Appointment.SURVEY_STATUS
  studies?: StudyData[]
  portalRules?: PortalRuleData[]
}

const dayFormat = 'L' // 'MM//DD/YYYY'
const timeFormat = 'LT' // 'h:mm A'
export class AppointmentModel extends BaseModel implements Breadcrumb {
  eid?: string
  ekey?: string
  cancellationReason?: string
  authorizations?: any[]
  authorizationStatuses?: AUTHORIZATION_STATUS[]
  referrals?: any[]
  referralStatuses?: REFERRAL_STATUS[]
  /** @deprecated, use eligibilityDetails */
  eligibilityStatus: Appointment.ELIGIBILITY_STATUS
  /** @deprecated, use eligibilityDetails */
  eligibilityId?: string
  eligibilityDetails?: Appointment.EligibilityDetails
  type: Appointment.TYPE
  startAt?: moment.Moment
  endAt?: moment.Moment
  duration?: number
  scheduledAt?: moment.Moment
  estimateId?: string
  estimate?: AppointmentEstimateSchema
  intakeId?: string
  intakeStatus: Appointment.INTAKE_STATUS
  surveyStatus?: Appointment.SURVEY_STATUS
  intakeProgress: number
  locationId: string
  location?: Facility
  referrerId?: string
  referrer?: ReferrerModel
  notes: string
  request?: { id: string, status: APPOINTMENT_REQUEST_STATUS }
  waitList?: Appointment.WaitList
  orderingPhysician?: string
  patientId: string
  patient?: Patient
  paymentStatus?: Appointment.PAYMENT_STATUS
  priority: number
  productIds: string[] = []
  products: ProductModel[] = []
  productCategories: string[] = []
  invoices: InvoiceModel[]
  status: Appointment.STATUS = Appointment.STATUS.OPEN
  statusDescription?: string
  transactions: TransactionModel[] = []
  transactionIds: string[] = []
  updatedAt: moment.Moment
  insurances: PatientInsurance[]
  workItems: WorkItemData[] = []
  /** @deprecated, use eligibilityDetails */
  eligibilities: { [key: string]: string }
  resourceIds?: string[]
  resources: Resource[] = []
  customFields?: Record<string, any>
  metadata?: Record<string, any>
  patientConfirmedAt?: moment.Moment
  patientConfirmedBy?: string | 'sms' | 'link'
  patientConfirmed?: boolean
  lastConfirmMsgSentAt?: moment.Moment
  timezone?: string
  orderedBy?: string
  scheduledBy?: string
  studies?: StudyData[]
  portalRules?: PortalRuleModel[]

  constructor(data?: AppointmentData) {
    super(data)

    this.type = Appointment.TYPE.APPOINTMENT

    if (data) {
      this.type = data.type ?? Appointment.TYPE.APPOINTMENT
      this.eid = data.eid
      this.ekey = data.ekey
      this.patientId = data.patientId
      this.locationId = data.locationId
      this.referrerId = data.referrerId
      this.startAt = this.type === Appointment.TYPE.ORDER ? undefined : this.transformDate(data.startAt)
      this.endAt = data.endAt ? this.transformDate(data.endAt) : this.startAt
      this.duration = data.duration
      this.status = data.status as Appointment.STATUS
      this.statusDescription = data.statusDescription
      this.priority = data.priority
      this.notes = data.notes
      this.request = data.request
      this.waitList = data.waitList
      this.authorizations = data.authorizations || []
      this.authorizationStatuses = data.authorizationStatuses || [AUTHORIZATION_STATUS.NONE]
      this.referrals = data.referrals || []
      this.referralStatuses = data.referralStatuses || [REFERRAL_STATUS.NONE]
      this.eligibilityStatus = data.eligibilityStatus as Appointment.ELIGIBILITY_STATUS
      this.eligibilityId = data.eligibilityId
      this.eligibilityDetails = data.eligibilityDetails || {}
      this.intakeStatus = data.intakeStatus as Appointment.INTAKE_STATUS
      this.intakeProgress = data.intakeProgress
      this.intakeId = data.intakeId
      this.estimateId = data.estimateId
      this.estimate = data.estimate
      this.paymentStatus = data.paymentStatus as Appointment.PAYMENT_STATUS
      this.cancellationReason = data.cancellationReason
      this.orderingPhysician = data.orderingPhysician
      this.productCategories = data.productCategories || []
      this.insurances = (data.insurances || []).map((insurance) => new PatientInsurance(insurance))
      this.eligibilities = data.eligibilities || {}
      this.patientConfirmedBy = data.patientConfirmedBy
      this.customFields = data.customFields
      this.metadata = data.metadata
      this.timezone = data.timezone
      this.orderedBy = data.orderedBy
      this.scheduledBy = data.scheduledBy
      this.surveyStatus = data.surveyStatus
      this.workItems = data.workItems || []
      this.studies = data.studies || []

      if (data.patient) {
        this.patient = new Patient(data.patient)
      }

      if (data.location) {
        this.location = new Facility(data.location)

        if (!this.timezone && this.location.timezone) {
          this.timezone = this.location.timezone
        }
      }

      if (data.referrer) {
        this.referrer = new ReferrerModel(data.referrer)
      }

      if (data.scheduledAt) {
        this.scheduledAt = this.transformDate(data.scheduledAt)
      }

      if (data.patientConfirmedAt) {
        this.patientConfirmedAt = this.transformDate(data.patientConfirmedAt)
      }

      if (data.lastConfirmMsgSentAt) {
        this.lastConfirmMsgSentAt = this.transformDate(
          data.lastConfirmMsgSentAt,
        )
      }

      if (typeof data.patientConfirmed === 'boolean') {
        this.patientConfirmed = data.patientConfirmed
      }

      if (isArrayLike(data.products) && data.products.length > 0) {
        if (isObjectLike(data.products[0])) {
          this.products = map(
            data.products,
            (productData) => new ProductModel(productData as ProductData),
          )
          this.productIds = map(this.products, (product) => product.id)
        } else {
          this.productIds = data.products as string[]
        }
      }

      if (isArrayLike(data.transactions) && data.transactions.length > 0) {
        if (isObjectLike(data.transactions[0])) {
          this.transactions = map(
            data.transactions,
            (transactionData) =>
              new TransactionModel(transactionData as TransactionData),
          )
          this.transactionIds = map(
            this.transactions,
            (transaction) => transaction.id,
          )
        } else {
          this.transactionIds = data.transactions as string[]
        }
      }

      if (isArrayLike(data.resources) && data.resources.length > 0) {
        if (isObjectLike(data.resources[0])) {
          this.resources = map(
            data.resources,
            (resourceData) => new Resource(resourceData as ResourceData),
          )
          this.resourceIds = map(this.resources, (resource) => resource.id)
        } else {
          this.resourceIds = data.resources as string[]
        }
      }

      if (isArrayLike(data.invoices) && data.invoices.length > 0) {
        if (isObjectLike(data.invoices[0])) {
          this.invoices = orderBy(
            map(data.invoices, (invoiceData) => new InvoiceModel(invoiceData)),
            'createdAt',
            'asc',
          )
        }
      }

      if (data.portalRules) {
        this.portalRules = data.portalRules.map((portal) => new PortalRuleModel(portal))
      }
    }
  }

  get text(): string {
    const eid = this.eid ? ` ${this.eid}` : ''
    if (this.type === Appointment.TYPE.ORDER || !this.startAt) {
      return `Order${eid}`
    } else {
      return `Appointment on ${this.dateAndTime}`
    }
  }

  get url(): string {
    return `/patient/${this.patientId}/appointment/${this.id}`
  }

  get time(): string {
    return this.getStringTime()
  }

  get dateAndTime(): string {
    return this.getDateAndTime()
  }

  get date(): string {
    if (this.startAt) {
      return this.startAt.format('MM/DD/YYYY')
    } else {
      return ''
    }
  }

  get notesHTML(): string {
    if (this.notes) {
      return this.notes.replace(/\n/g, '<br>')
    }
  }

  get cancelled(): boolean {
    return this.status === Appointment.STATUS.CANCELLED
  }

  get hasErrors(): boolean {
    return false
  }

  get eligibilityPrimaryStatus(): Appointment.ELIGIBILITY_STATUS {
    return this.eligibilityDetails?.primary?.status || this.eligibilityStatus || Appointment.ELIGIBILITY_STATUS.NONE
  }

  get eligibilityPrimaryId(): string {
    return this.eligibilityDetails?.primary?.id || this.eligibilityId
  }

  get eligibilitySecondaryStatus(): Appointment.ELIGIBILITY_STATUS {
    return this.eligibilityDetails?.secondary?.status || Appointment.ELIGIBILITY_STATUS.NONE
  }

  get paymentStatusText(): string {
    switch (this.paymentStatus) {
      case Appointment.PAYMENT_STATUS.NONE:
        return 'appointment.payment-status-text.none'
      case Appointment.PAYMENT_STATUS.CONFIRMED:
        return 'appointment.payment-status-text.confirmed'
      case Appointment.PAYMENT_STATUS.ON_FILE:
        return 'appointment.payment-status-text.on-file'
      case Appointment.PAYMENT_STATUS.CHARGEABLE:
        return 'appointment.payment-status-text.chargeable'
      case Appointment.PAYMENT_STATUS.PARTIAL:
        return 'appointment.payment-status-text.partial'
      case Appointment.PAYMENT_STATUS.FULL:
        return 'appointment.payment-status-text.full'
      case Appointment.PAYMENT_STATUS.REFUND_DUE:
        return 'appointment.payment-status-text.refund-due'
      default:
        return 'appointment.payment-status-text.default'
    }
  }

  get validInvoices(): InvoiceModel[] {
    const statusInvoiceActive = [
      Invoice.STATUS.CONFIRMED,
      Invoice.STATUS.PAYING,
      Invoice.STATUS.PARTIAL,
      Invoice.STATUS.PAID,
    ]
    return filter(this.invoices || [], (i) =>
      statusInvoiceActive.includes(i.status),
    )
  }

  get hasInvoice(): InvoiceModel {
    const statusInvoiceActive = [
      Invoice.STATUS.CONFIRMED,
      Invoice.STATUS.PAID,
      Invoice.STATUS.PARTIAL,
    ]
    const invoice = findLast(this.invoices || [], (i) =>
      includes(statusInvoiceActive, i.status),
    )
    return invoice
  }

  get vip(): boolean {
    return !(this.patient && this.patient.vip)
  }

  get confirmationStatusSortKey(): number {
    if (this.patientConfirmed === true) {
      return 1
    } else if (this.patientConfirmed === false) {
      return 0
    } else if (
      this.patientConfirmed === undefined &&
      this.lastConfirmMsgSentAt !== undefined
    ) {
      return 2
    } else if (
      this.patientConfirmed === undefined &&
      this.lastConfirmMsgSentAt === undefined
    ) {
      return 3
    }
  }

  get isSurveySent(): boolean {
    return [
      Appointment.SURVEY_STATUS.PENDING,
      Appointment.SURVEY_STATUS.SUBMITTED,
    ].includes(this.surveyStatus)
  }

  convertToFacilityTime(
    date: moment.Moment,
    facility?: Facility,
  ): moment.Moment {
    if (facility?.timezone) {
      return date.tz(facility.timezone)
    } else if (this.location?.timezone) {
      return date.tz(this.location.timezone)
    } else {
      return date.local()
    }
  }

  getDate() {
    if (
      this.startAt &&
      (!this.endAt || !this.endAt.isValid() || this.startAt.isSame(this.endAt))
    ) {
      return this.startAt.local().format(`${dayFormat}`)
    }

    if (this.startAt && this.endAt) {
      const sameDay = this.startAt.dayOfYear() === this.endAt.dayOfYear()

      if (sameDay) {
        return this.startAt.local().format(dayFormat)
      } else {
        const start = this.startAt.local().format(`${dayFormat}`)
        const end = this.endAt.local().format(`MM/DD`)
        return `${start} - ${end}`
      }
    }

    return ''
  }

  getStringTime(
    facility?: Facility,
    which: 'startAt' | 'endAt' = 'startAt',
  ): string {
    const date = this.convertToFacilityTime(this[which].clone(), facility)
    return date.format(timeFormat)
  }

  getTime(which: 'startAt' | 'endAt' = 'startAt'): moment.Moment {
    const date = this.convertToFacilityTime(this[which].clone(), this.location)
    return date
  }

  getTimeTooltip(facility?: Facility): string | undefined {
    return getFacilityAndLocalTime(this.startAt, {
      facility: facility || this.location,
      tz: this.timezone,
    })
  }

  getDateAndTime(facility?: Facility) {
    if (this.startAt == null) {
      return ''
    }

    const startAt = this.convertToFacilityTime(this.startAt.clone(), facility)
    const endAt =
      this.endAt && this.endAt.isValid()
        ? this.convertToFacilityTime(this.endAt.clone(), facility)
        : undefined

    if (startAt) {
      if (!endAt || startAt.isSame(endAt, 'minute')) {
        return startAt.format(`${dayFormat} ${timeFormat}`)
      } else if (endAt) {
        const sameDay = startAt.dayOfYear() === this.endAt.dayOfYear()
        const start = startAt.format(`${dayFormat} ${timeFormat}`)
        const end = endAt.format(sameDay ? timeFormat : `MM/DD ${timeFormat}`)
        return `${start} - ${end}`
      }
    }

    return ''
  }

  upsertInsurance(insurance: PatientInsurance) {
    const index = this.insurances.findIndex((ins) => ins.id === insurance.id)
    if (index >= 0) {
      this.insurances[index] = insurance
    } else {
      this.insurances.push(insurance)
    }
  }

  removeInsurance(insurance: PatientInsurance) {
    pull(this.insurances, insurance)
  }

  toJSON(): any {
    let products: any = this.productIds
    let resources: any = this.resourceIds
    let transactions: any = this.transactionIds

    if (this.products.length > 0) {
      products = this.products.map((product) => product.id)
    }

    if (this.resources.length > 0) {
      resources = this.resources.map((resource) => resource.id)
    }

    if (this.transactions.length > 0) {
      transactions = this.transactions.map((transaction) => transaction.id)
    }

    return extend(super.toJSON(), {
      patient: this.patientId,
      locationId: this.locationId,
      referrerId: this.referrerId,
      type: this.type,
      startAt: this.startAt?.toISOString(),
      endAt: this.endAt?.toISOString(),
      duration: this.duration,
      status: this.status,
      statusDescription: this.statusDescription,
      priority: this.priority,
      orderingPhysician: this.orderingPhysician,
      notes: this.notes,
      waitList: this.waitList,
      workItems: this.workItems,
      intakeId: this.intakeId,
      intakeStatus: this.intakeStatus,
      eligibilityId: this.eligibilityId,
      eligibilities: this.eligibilities,
      eligibilityStatus: this.eligibilityStatus,
      paymentStatus: this.paymentStatus,
      referralStatuses: this.referralStatuses,
      authorizationStatuses: this.authorizationStatuses,
      insurances: this.insurances,
      resources,
      products,
      transactions,
      customFields: this.customFields,
      studies: this.studies,
    })
  }
}

export namespace Appointment {
  export enum TYPE {
    APPOINTMENT = 'A',
    ORDER = 'O',
  }

  export interface EligibilityDetails {
    primary?: InsuranceEligibility
    secondary?: InsuranceEligibility
    tertiary?: InsuranceEligibility
  }

  export interface WaitList {
    status: WAIT_LIST_STATUS
    messageBy?: string
    messageAt?: Date
    createdAt: Date
  }

  export interface InsuranceEligibility {
    id?: string
    status: ELIGIBILITY_STATUS
  }

  export enum STATUS {
    OPEN = 'O',
    HOLD = 'H',
    CANCELLED = 'C',
    RESCHEDULE = 'R',
    ARRIVED = 'A',
    CHECKING_IN = 'CI',
    CHECKED_IN = 'W',
    IN_PROGRESS = 'I',
    ABORTED = 'B',
    COMPLETED = 'D',
    NO_SHOW = 'N',
    RESULTS_READY = 'RR',
  }

  export enum WAIT_LIST_STATUS {
    OPEN = 'O',
    WAITING = 'W', // waiting for patient to respond after sending available times
    COMPLETED = 'D', // the staff has accepted an earlier time
    CANCELLED = 'C',
  }

  export enum PAYMENT_STATUS {
    NONE = 'N',
    CONFIRMED = 'C',
    ON_FILE = 'I',
    CHARGEABLE = 'A',
    PARTIAL = 'P',
    FULL = 'F',
    REFUND_DUE = 'R',
  }

  export enum INTAKE_STATUS {
    NONE = 'N',
    ACKNOWLEDGED = 'A',
    DELIVERED = 'D',
    PENDING = 'P',
    SUBMITTED = 'S',
    CONFIRMED = 'C',
    TECH_CONFIRMED = 'T',
    TECH_REVIEW = 'W',
    SYNCING = 'U',
    SYNCED = 'Y',
    NOT_REQUIRED = 'R',
  }

  export enum ELIGIBILITY_STATUS {
    NONE = 'N',
    HARD_FAIL = 'F',
    SOFT_FAIL = 'E',
    INVALID = 'IV',
    INACTIVE = 'I',
    ACTIVE = 'V',
    AVOIDED = 'A',
  }

  export enum INSURANCE_TYPE {
    PRIMARY = 'P',
    SECONDARY = 'S',
    TERTIARY = 'T',
  }

  export enum SURVEY_STATUS {
    NONE = 'N',
    PENDING = 'P',
    SUBMITTED = 'S',
  }
}
