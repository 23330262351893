import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  Card,
  CardData,
  Consent,
  Facility,
  FacilityData,
  InsuranceProvider,
  IQuestion,
  ISection,
  ISectionPDFDocumentValue,
  PagedParams,
  PagedRestResponse,
  Patient,
  PatientData,
  PatientPalRestError,
  Role,
  RoleData,
  toData,
} from '@mmx/shared'
import { isString, map, orderBy } from 'lodash'
import moment from 'moment'
import { Observable, of, throwError } from 'rxjs'
import { catchError, map as oMap, tap } from 'rxjs/operators'

import { PatientIntakeFormData } from '../../patient-intake/classes/intake-form-data.class'
import { NOT_FOUND } from '../data'
import { InvoicesStats } from '../data/invoices-stats'
import { PDFDownloadLink } from '../data/pdf-download-link'
import {
  AppointmentData,
  AppointmentHistoryData,
  AppointmentModel,
  AppointmentRequest,
  AppointmentRequestData,
  ChargeDescriptionMaster,
  ChargeDescriptionMasterData,
  Clinic,
  Eligibility,
  EligibilityData,
  EstimateTemplateModel,
  EventRecord,
  EventRecordData,
  IntakeData,
  IntakeModel,
  InvoiceData,
  InvoiceModel,
  MessageHistoryRecordData,
  MessageHistoryRecordModel,
  MessageTemplate,
  MessageTemplateData,
  MessageTemplateModel,
  Note,
  NoteData,
  Notification,
  NotificationData,
  NotificationModel,
  PatientDocument,
  PatientUploadedFile,
  Price,
  Procedure,
  ProcedureData, ProceduresEstimate, ProceduresEstimateItem, ProceduresEstimateResponse,
  ProductCategory,
  ProductCategoryData,
  ProductData, ProductModel,
  TransactionData,
  TransactionModel,
  Workflow,
} from '../models'
import { AppointmentEstimateSchema } from '../schemas/estimate.schema'
import { standardizePhone } from '../utils/phone'
import { AuthenticatedRestService } from './auth-rest.service'

export type CsvDelimiter = ',' | 'tab' | ';'
export type CsvQuote = '"' | "'"

export interface PaymentResponse {
  invoice: InvoiceData
  transaction: TransactionData
}

export interface RefundResponse {
  id: string
  status?: boolean
}

export interface CaptureResponse {
  id: string
  status?: boolean
}

export interface IntakeResponse {
  id: string
  patientId: string
  appointmentId: string
}

export interface ListUsersResponse {
  has_more: boolean
  items: ListUser[]
  offset?: number | 0
  limit?: number | 0
  total?: number | 0
}

export interface ListUser {
  id: string
  name: string
  email: string
  account_id: string
  role: string
  last_login_at?: Date
  last_request_at?: Date
  created_at: Date
  updated_at: Date
}

export interface GetPatientsInput extends PagedParams {
  familyName?: string
  givenName?: string
  dob?: string | Date | moment.Moment
  phone?: string
  email?: string
  lastEvaluatedId?: string
}

export interface GetAppointmentRequestsInput extends PagedParams {
  requestedDate?: string | Date | moment.Moment
  status?: string | string[]
  locationId?: string
}

export interface GetInventoryInput extends PagedParams {
  categories?: string | string[]
  carriers?: string | string[]
  locationId?: string
}

export interface GetInvoicesInput extends PagedParams {
  paid?: boolean
  createdAt?: string | Date | moment.Moment
  patientId?: string
  fromDate?: string | Date | moment.Moment
  toDate?: string | Date | moment.Moment
  status?: string | string[]
}

export interface GetTransactionsInput extends PagedParams {
  paid?: boolean
  createdAt?: string | Date | moment.Moment
  patientId?: string
  fromDate?: string | Date | moment.Moment
  toDate?: string | Date | moment.Moment
  status?: string | string[]
}

export interface GetAppointmentsInput extends PagedParams {
  patientId?: string
  start?: string | Date | moment.Moment
  end?: string | Date | moment.Moment
  status?: string
  isStar?: string
  extend?: string
  locationId?: string
  categoryId?: string
  vipPatients?: string
  project?: string
  waitList?: boolean
  hideCompletedAppointments?: boolean
  hidePastAppointments?: boolean
  productCategories?: string[]
}

export interface ContactSupportFileData {
  fileName: string;
  content: string | ArrayBuffer;
}

export interface ContactSupportParams {
  message: string;
  attachment?: ContactSupportFileData;
}

export interface AvailableTimesRequestParams {
  start: string
  end: string
  appointmentRequest: string
}

export type SendPatientMessageMethod = 'email' | 'sms'

@Injectable({
  providedIn: 'root',
})
export class ApiService extends AuthenticatedRestService {
  private cache: {
    eligibilities: { [key: string]: Eligibility }
    intakes: { [key: string]: IntakeModel }
    patients: { [key: string]: Patient }
    productCategories: ProductCategory[] | null
    payers?: InsuranceProvider[]
  } = {
      eligibilities: {},
      intakes: {},
      patients: {},
      productCategories: null,
    }

  getIntegrationSupport() {
    const route = `/integration/support`
    return this.get<any>(route)
  }

  getLocalities(): Observable<any[]> {
    return this.get<any[]>('/cms/localities').pipe(
      oMap((response) => response.data),
    )
  }

  getPayers(): Observable<InsuranceProvider[]> {
    if (this.cache.payers) {
      return of(this.cache.payers)
    }
    const route = `/insurances/payers`
    return this.get<InsuranceProvider[]>(route).pipe(
      oMap((response) => orderBy(
        response.data.map((carrier) => new InsuranceProvider(carrier)),
        'name',
      )),
      tap((payers) => this.cache.payers = payers),
    )
  }

  search(criteria: GetPatientsInput): Observable<PagedRestResponse<Patient[]>> {
    return this.getPatients(criteria)
  }

  createAppointment(appointment: AppointmentModel): Observable<AppointmentModel> {
    const route = `/appointments`
    return this.post<AppointmentData>(route, appointment).pipe(
      oMap((response) => {
        return new AppointmentModel(response.data)
      }),
    )
  }

  updateAppointment(appointment: AppointmentModel): Observable<AppointmentModel> {
    const route = `/appointments/${appointment.id}`
    return this.put<AppointmentData>(route, appointment).pipe(
      oMap((response) => {
        return new AppointmentModel(response.data)
      }),
    )
  }

  patchAppointment(id: string, body?: any): Observable<AppointmentModel> {
    const route = `/appointments/${id}`
    return this.patch<AppointmentData>(route, body).pipe(
      oMap((response) => {
        return new AppointmentModel(response.data)
      }),
    )
  }

  getAppointment(id: string): Observable<AppointmentModel> {
    const route = `/appointments/${id}`
    return this.get<AppointmentData>(route).pipe(
      oMap((response) => new AppointmentModel(response.data)),
    )
  }

  lookupAppointment(
    query: any,
  ): Observable<{
    clinicId: string
    patientId: string
    appointmentId: string
  }> {
    const route = `/appointments/lookup`
    const params = this.convertToParams(query)
    return this.get<any>(route, params).pipe(
      oMap((response) => {
        return response.data
      }),
    )
  }

  mergeAppointments(ids: string[]): Observable<AppointmentModel> {
    const route = `/appointments/merge`
    return this.post<AppointmentData>(route, { ids }).pipe(
      oMap((response) => {
        return new AppointmentModel(response.data)
      }),
    )
  }

  getAppointments(
    filters: GetAppointmentsInput,
  ): Observable<PagedRestResponse<AppointmentModel[]>> {
    if (filters.start) {
      filters.start = moment(filters.start).toISOString()
    }

    if (filters.end) {
      filters.end = moment(filters.end).toISOString()
    }

    const route = `/appointments`
    const params = new HttpParams({ fromObject: filters as any })
    return this.get<AppointmentData[]>(route, params).pipe(
      oMap((response) => {
        const resp: PagedRestResponse<AppointmentModel[]> = {
          ...response,
          data: map(
            response.data,
            (appointmentData) => new AppointmentModel(appointmentData),
          ),
        }
        return resp
      }),
    )
  }

  batchAppointments(ids: string[], action: string, data?: any) {
    const route = `/appointments/batch`
    return this.post<any>(route, {
      ids,
      action,
      data,
    })
  }

  getAppointmentRequests(
    input: GetAppointmentRequestsInput = {},
  ): Observable<PagedRestResponse<AppointmentRequest[]>> {
    const route = '/appointment-requests'
    const params = this.convertToParams(input as any)
    return this.get<AppointmentRequestData[]>(route, params).pipe(
      oMap((response) => {
        const result: PagedRestResponse<AppointmentRequest[]> = {
          ...response,
          data: response.data.map(
            (requestData) => new AppointmentRequest(requestData),
          ),
        }
        return result
      }),
    )
  }

  getAppointmentRequest(
    id: string,
    params: Record<string, string | number | boolean> = {},
  ): Observable<AppointmentRequest> {
    return this.get<AppointmentRequestData>(
      `/appointment-requests/${id}`,
      this.convertToParams(params),
    )
      .pipe(
        oMap((response) => new AppointmentRequest(response.data)),
      )
  }

  deleteAppointmentRequest(id: string): Observable<any> {
    const route = `/appointment-requests/${id}`
    return this.delete<any>(route)
  }

  updateAppointmentRequest(apptRequest: AppointmentRequest, book?: boolean) {
    const route = `/appointment-requests/${apptRequest.id}`
    const params = this.convertToParams({ book })
    return this.put<AppointmentRequestData>(route, apptRequest.toJSON(), { params }).pipe(
      oMap((response) => new AppointmentRequest(response.data)),
    )
  }

  biDemographics(patientId: string, direction: 'in' | 'out' = 'in') {
    const route = `/patients/${patientId}/bi-demographics`
    const body = {
      direction,
      patient: patientId,
    }
    return this.post(route, body)
  }

  getPatient(id: string, ignoreCache = false): Observable<Patient> {
    if (!ignoreCache && this.cache.patients[id]) {
      return of(this.cache.patients[id])
    }

    const route = `/patients/${id}`
    return this.get<PatientData>(route).pipe(
      oMap((response) => {
        const patient = new Patient(response.data)
        this.cache.patients[id] = patient
        return patient
      }),
    )
  }

  createPatient(patient: Patient) {
    const route = '/patients'
    return this.post<PatientData>(route, patient.toJSON())
  }

  updatePatient(patient: string, body: any)
  updatePatient(patient: Patient)
  updatePatient(patient: string | Patient, body?: any) {
    const id = typeof patient === 'string' ? patient : patient.id
    if (patient instanceof Patient) {
      body = patient.toJSON()
    }

    const route = `/patients/${id}`
    return this.put<PatientData>(route, body)
  }

  deletePatient(patient: Patient) {
    const route = `/patients/${patient.id}`
    return this.delete<any>(route)
  }

  getPatientDocuments(patientId: string, types: string[] = []) {
    const params = this.convertToParams({ types })
    const route = `/patients/${patientId}/documents`
    return this.get<any[]>(route, params).pipe(
      oMap(res => orderBy(map(res.data, documentData => new PatientDocument(documentData)), 'createdAt', 'desc')),
    )
  }

  getPatientIntakeForms(patient: string) {
    const route = `/intakes`
    const params = new HttpParams({
      fromObject: {
        patient,
      },
    })
    return this.get<IntakeModel[]>(route, params)
  }

  getIntake(intakeId: string): Observable<IntakeModel> {
    if (this.cache.intakes[intakeId]) {
      return of(this.cache.intakes[intakeId])
    }
    const route = `/intakes/${intakeId}`
    return this.get<IntakeData>(route).pipe(
      oMap((response) => {
        const intake = new IntakeModel(response.data)
        this.cache.intakes[intakeId] = intake
        return intake
      }),
    )
  }

  getIntakePdfLink(
    intakeId: string,
    options: {
      file?: string
      html?: boolean
    } = {
      html: false,
    },
  ) {
    const route = `/intakes/${intakeId}/pdf`
    const params = new HttpParams({
      fromObject: {
        file: options.file,
        html: options.html === true ? 'true' : 'false',
      },
    })
    return this.get<PDFDownloadLink>(route, params)
  }

  getIntakePDFDocuments(intakeId: string) {
    return this.get<ISectionPDFDocumentValue[]>(`/intakes/${intakeId}/pdf-documents`)
  }

  createIntakeCode(appointmentId: string) {
    const route = `/appointments/${appointmentId}/intake-code`
    return this.post<{ code: string }>(route, {})
  }

  createIntake(intake: IntakeModel) {
    const route = `/intakes`
    return this.post<IntakeResponse>(route, intake)
  }

  updateIntake(intake: PatientIntakeFormData) {
    const route = `/intakes/${intake.id}`
    return this.put<IntakeModel>(route, { intakeData: intake }).pipe(
      oMap((response) => new IntakeModel(response.data)),
    )
  }

  getPatientEligibilities(patientId: string): Observable<Eligibility[]> {
    return this.getEligibilities({ patientId }).pipe(
      oMap((resp) => resp.data),
    )
  }

  getEligibilities(query: PagedParams & { patientId?: string }): Observable<PagedRestResponse<Eligibility[]>> {
    const route = `/eligibilities`
    return this.get<EligibilityData[]>(route, this.convertToParams(query as any)).pipe(
      oMap((resp) => {
        return {
          ...resp,
          data: map(resp.data, (eligibility) => new Eligibility(eligibility)),
        }
      }),
    )
  }

  getEligibility(
    eligibilityId: string,
    includeRaw = false,
  ): Observable<Eligibility> {
    if (this.cache.eligibilities[eligibilityId] && !includeRaw) {
      return of(this.cache.eligibilities[eligibilityId])
    }
    const route = `/eligibilities/${eligibilityId}`
    const params = this.convertToParams({ raw: includeRaw })
    return this.get<EligibilityData>(route, params).pipe(
      oMap((resp) => {
        const eligibility = new Eligibility(resp.data)
        this.cache.eligibilities[eligibilityId] = eligibility
        return eligibility
      }),
    )
  }

  updatePatientEligibility(eligibility: Eligibility) {
    const route = `/eligibilities/${eligibility.id}`
    return this.put<EligibilityData>(route, eligibility.toJSON())
  }

  getEligibilityPdfLink(
    eligibilityId: string,
    asHtml?: boolean,
  ) {
    const route = `/eligibilities/${eligibilityId}/pdf`
    const params = new HttpParams({
      fromObject: {
        html: asHtml ? 'true' : 'false',
      },
    })
    return this.get<PDFDownloadLink>(route, params)
  }

  getAppointmentEstimate(appointmentId: string) {
    const route = `/appointments/${appointmentId}/estimate`
    return this.get<any>(route).pipe(
      oMap((resp) => resp.data),
    )
  }

  getModelClaim(query: { query: string, facilityId: string, serviceType: 'I' | 'O' }): Observable<any[]> {
    const route = `/estimates/model-claim-search`
    const params = this.convertToParams(query)
    return this.get<any[]>(route, params).pipe(
      oMap((response) => response.data),
    )
  }

  getEstimateTemplate(templateId: string): Observable<EstimateTemplateModel> {
    const route = `/estimates/templates/${templateId}`
    return this.get<EstimateTemplateModel>(route).pipe(
      oMap((response) => new EstimateTemplateModel(response.data)),
    )
  }

  getEstimateTemplates(query: { name: string, facilityId: string }): Observable<EstimateTemplateModel[]> {
    const route = `/estimates/templates/search`
    const params = this.convertToParams(query)
    return this.get<EstimateTemplateModel[]>(route, params).pipe(
      oMap((response) => {
        return map(response.data, (template) => new EstimateTemplateModel(template))
      }),
    )
  }

  getClaims(name: string): Observable<any[]> {
    const route = `/estimates/claims`
    const params = this.convertToParams({ name })
    return this.get<any[]>(route, params).pipe(
      oMap((response) => {
        return map(response.data, (template) => template)
      }),
    )
  }

  getInventory(
    input: GetInventoryInput = {},
  ): Observable<any> {
    const route = '/appointments/inventory'
    const params = this.convertToParams(input as any)
    return this.get<any>(route, params)
  }

  getInventoryDetails(
    input: GetInventoryInput = {},
  ) {
    const route = '/appointments/inventory/details'
    const params = this.convertToParams(input as any)
    return this.get<any>(route, params).pipe(
      oMap((response) => {
        return {
          ...response,
          data: map(
            response.data,
            (appointmentData) => new AppointmentModel(appointmentData),
          ),
        }
      }),
    )
  }

  updateAppointmentEstimate(appointmentId: string, estimate: AppointmentEstimateSchema) {
    const route = `/appointments/${appointmentId}/estimate`
    return this.put<any>(route, estimate)
  }

  sendPatientMessage(
    patientId: string,
    params: {
      type: MessageTemplate.TYPE
      method: SendPatientMessageMethod
      appointmentId?: string
      appointmentRequestId?: string
      data?: any
      translatedText?: string
      translate?: boolean
      internal?: boolean
    },
  ) {
    const route = `/patients/${patientId}/messages`
    return this.post<any>(route, params)
  }

  getPatients(
    input: GetPatientsInput = {},
  ): Observable<PagedRestResponse<Patient[]>> {
    if (isString(input.phone)) {
      input.phone = standardizePhone(input.phone)
    }

    const route = '/patients'
    const params = this.convertToParams(input as any)
    return this.get<PatientData[]>(route, params).pipe(
      oMap((response) => {
        const result: PagedRestResponse<Patient[]> = {
          ...response,
          data: response.data.map((patientData) => new Patient(patientData)),
        }
        return result
      }),
    )
  }

  updateClinician(data: any) {
    const route = '/clinicians/profile'
    return this.put<void>(route, data)
  }

  getRoles(): Observable<Role[]> {
    const route = '/roles'
    return this.get<RoleData[]>(route).pipe(
      oMap((response) => {
        const result = response.data.map((roleData) => new Role(roleData))
        return result
      }),
    )
  }

  getClinicianNotifications(input?: {
    status?: Notification.STATUS
    patient?: string
    facility?: string
  }): Observable<PagedRestResponse<NotificationModel[]>> {
    const route = `/clinicians/notifications`
    if (input.status == null) {
      delete input.status
    }
    const params = this.convertToParams(input)
    return this.get<NotificationData[]>(route, params).pipe(
      oMap((response) => {
        const result: PagedRestResponse<NotificationModel[]> = {
          ...response,
          data: response.data.map(
            (notification) => new NotificationModel(notification),
          ),
        }
        return result
      }),
    )
  }

  contactSupport(params: ContactSupportParams) {
    const route = `/clinicians/contact-support`

    return this.post<any>(route, params)
  }

  updateClinicianNotification(notificationId: string, status: string) {
    const route = `/clinicians/notifications/${notificationId}`
    return this.put<void>(route, { status })
  }

  getPatientNotes(patientId: string, appointmentId?: string): Observable<Note[]> {
    const route = `/patients/${patientId}/notes`
    const params = appointmentId ? this.convertToParams({ appointmentId }) : undefined
    return this.get<NoteData[]>(route, params).pipe(
      oMap((response) => {
        return map(response.data, (note) => new Note(note))
      }),
    )
  }

  createNote(note: NoteData) {
    const route = `/patients/${note.patientId}/notes`
    return this.post<NoteData>(route, note)
  }

  processPayment(patientId, data: any) {
    const route = `/payments/patients/${patientId}/charges`
    return this.post<PaymentResponse>(route, data)
  }

  refundTransaction(refundDetails: any) {
    const route = `/payments/charges/refund`
    return this.post<RefundResponse>(route, { refundDetails })
  }

  captureTransaction(captureDetails: any) {
    const route = `/payments/charges/capture/create`
    return this.post<CaptureResponse>(route, { captureDetails })
  }

  cancelCaptureTransaction(captureDetails: any) {
    const route = `/payments/charges/capture/cancel`
    return this.post<any>(route, { captureDetails })
  }

  closeDispute(disputeId: string, paymentProvider: string) {
    const route = `/payments/disputes/${disputeId}/close`
    const params = this.convertToParams({ paymentProvider })
    return this.post<any>(route, {}, { params })
  }

  getPatientCreditCards(patientId: string): Observable<Card[]> {
    const route = `/payments/patients/${patientId}/payment-methods`
    return this.get<any>(route).pipe(
      oMap((response) => {
        return map(response.data, (card) => new Card(card))
      }),
    )
  }

  getPatientTransactions(
    patientId: string,
    filters: any = {},
  ): Observable<TransactionModel[]> {
    const route = `/transactions`
    filters.patient = patientId
    const params = new HttpParams({ fromObject: filters })

    return this.get<TransactionData[]>(route, params).pipe(
      oMap((response) => {
        return map(response.data, (transactionData) => {
          return new TransactionModel(transactionData)
        })
      }),
    )
  }

  getTransaction(transactionId: string) {
    const route = `/transactions/${transactionId}`
    return this.get<TransactionData>(route).pipe(
      oMap((response) => new TransactionModel(response.data)),
    )
  }

  getTransactionReferences(transactionId: string) {
    const route = `/transactions/${transactionId}/references`
    return this.get<TransactionData[]>(route).pipe(
      oMap((response) => {
        return map(
          response.data,
          (transactionData) => new TransactionModel(transactionData),
        )
      }),
    )
  }

  getTransactionPDF(transactionId: string) {
    const route = `/transactions/${transactionId}/pdf`
    return this.get<PDFDownloadLink>(route)
  }

  sendTransactionMessage(patientId: string, transactionId: string) {
    const route = `/transactions/${transactionId}/patients/${patientId}/message`
    return this.get<PDFDownloadLink>(route)
  }

  getTransactions(
    input: GetTransactionsInput = {},
  ): Observable<PagedRestResponse<TransactionModel[]>> {
    const route = '/transactions'
    const params = this.convertToParams(input as any)
    return this.get<TransactionModel[]>(route, params).pipe(
      oMap((response) => {
        const result: PagedRestResponse<TransactionModel[]> = {
          ...response,
          data: response.data.map(
            (transaction) => new TransactionModel(transaction),
          ),
        }
        return result
      }),
    )
  }

  createUserInvitation(email: string, role: string) {
    const route = `/payments-tilled/users/invitations`
    return this.post<any>(route, { email, role })
  }

  getListUsers(limit?: number | 0, offset?: number | 0) {
    const route = `/payments-tilled/users`
    const params = new HttpParams({
      fromObject: {
        limit: limit || 0,
        offset: offset || 0,
      },
    })
    return this.get<ListUsersResponse>(route, params)
  }

  submitInvoicePayment(invoiceId: string, pay: any) {
    const route = `/invoices/${invoiceId}/pay`
    return this.post<any>(route, pay)
  }

  generateInvoicePDF(invoiceId: string, asHtml?: boolean) {
    const route = `/invoices/${invoiceId}/pdf`
    const params = new HttpParams({
      fromObject: {
        html: asHtml ? 'true' : 'false',
      },
    })
    return this.get<PDFDownloadLink>(route, params)
  }

  getInvoice(invoiceId: string): Observable<InvoiceModel> {
    const route = `/invoices/${invoiceId}`
    return this.get<InvoiceData>(route).pipe(
      oMap((resp) => {
        return new InvoiceModel(resp.data)
      }),
    )
  }

  getInvoices(
    input: GetInvoicesInput = {},
  ): Observable<PagedRestResponse<InvoiceModel[]>> {
    const route = '/invoices'
    const params = this.convertToParams(input as any)
    return this.get<InvoiceData[]>(route, params).pipe(
      oMap((response) => {
        const result: PagedRestResponse<InvoiceModel[]> = {
          ...response,
          data: response.data.map((invoice) => new InvoiceModel(invoice)),
        }
        return result
      }),
    )
  }

  getInvoicesStats() {
    const route = `/invoices/stats`
    return this.get<InvoicesStats>(route)
  }

  sendInvoiceMessage(
    invoiceId: string,
    params: {
      method: string
    },
  ) {
    const route = `/invoices/${invoiceId}/message`
    return this.post<any>(route, params)
  }

  updateInvoice(invoice: InvoiceModel) {
    return this.put<void>(`/invoices/${invoice.id}`, invoice.toJSON())
  }

  getProductCategories(): Observable<ProductCategory[]> {
    const route = `/products/categories`
    return this.get<ProductCategoryData[]>(route).pipe(
      oMap((response) => response.data.map((c) => new ProductCategory(c))),
    )
  }

  getPatientUploadedFiles(id: string, intakeId?: string) {
    const route = `/patients/${id}/files`
    const params = intakeId
      ? new HttpParams({ fromObject: { intake: intakeId } })
      : undefined
    return this.get<PatientUploadedFile[]>(route, params)
  }

  uploadPatientFiles(
    patient: string,
    files: {
      fileName: string
      content: string
      intakeId?: string
      intakeFile?: string
    }[],
  ) {
    const route = `/patients/${patient}/files`
    return this.post<any>(route, files)
  }

  deletePatientFile(input: {
    patientId: string
    key: string
  }): Observable<any> {
    const route = `/patients/${input.patientId}/files`
    const params = this.convertToParams({ key: input.key })
    return this.delete<void>(route, { params })
  }

  getPatientHistory(id: string, filters?: any): Observable<EventRecord[]> {
    const route = `/patients/${id}/history`
    return this.get<EventRecordData[]>(route, this.convertToParams(filters)).pipe(
      oMap((response) => {
        return map(response.data, (log) => new EventRecord(log))
      }),
    )
  }

  getPatientMessageHistory(id: string) {
    const route = `/patients/${id}/messages/history`
    return this.get<MessageHistoryRecordData[]>(route).pipe(
      oMap((response) => {
        return orderBy(
          map(
            response.data as MessageHistoryRecordData[],
            (record) => new MessageHistoryRecordModel(record),
          ),
          'createdAt',
          'desc',
        )
      }),
    )
  }

  getRecentConversations() {
    const route = `/patients/conversations`
    return this.get<any[]>(route)
  }

  getMessageHistory(input: {
    patientId?: string
    fromDate?: string | Date | moment.Moment
    toDate?: string | Date | moment.Moment
    direction?: string
    lastEvaluatedId?: string
  }): Observable<PagedRestResponse<MessageHistoryRecordModel[]>> {
    const route = `/patients/messages` // TODO: need to remap this route
    const params = this.convertToParams(input)
    return this.get<MessageHistoryRecordData[]>(route, params).pipe(
      oMap((response) => {
        const resp: PagedRestResponse<MessageHistoryRecordModel[]> = {
          ...response,
          data: map(
            response.data,
            (record) => new MessageHistoryRecordModel(record),
          ),
        }
        return resp
      }),
    )
  }

  translate(input: {
    input: string | string[]
    inputLanguage?: string
    outputLanguage: string
    format?: 'text' | 'html'
  }) {
    const route = `/utils/translate`
    return this.post<{ translatedText: string }[]>(route, input)
  }

  putClinic(clinic: Clinic) {
    const route = `/clinic-settings`
    return this.put<void>(route, clinic.toJSON())
  }

  createQuestion(question: IQuestion) {
    const route = `/clinic-settings/questions`
    return this.post<IQuestion>(route, question)
  }

  updateQuestion(question: IQuestion): Observable<void> {
    const route = `/clinic-settings/questions/${question.id}`
    return this.put<void>(route, question).pipe(oMap(() => {}))
  }

  reorderQuestions(questions: IQuestion[]): Observable<void> {
    const route = `/clinic-settings/reorder-intake`
    return this.post<void>(route, { type: 'questions', questions }).pipe(
      oMap(() => {}),
    )
  }

  putIntakeConsents(consents: Consent[]): Observable<void> {
    const route = '/clinic-settings/consents'
    return this.put<void>(route, { consents }).pipe(oMap(() => {}))
  }

  upsertSection(section: ISection) {
    const route = `/clinic-settings/sections`
    return this.put<ISection>(route, section)
  }

  reorderSections(sections: ISection[]): Observable<void> {
    const route = `/clinic-settings/reorder-intake`
    const data = sections.map((section) => {
      return {
        id: section.id,
        label: section.label,
        order: section.order,
      }
    })
    return this.post<void>(route, { type: 'sections', sections: data }).pipe(
      oMap(() => {}),
    )
  }

  deleteSection(section: ISection): Observable<void> {
    const route = `/clinic-settings/sections/${section.id}`
    return this.delete<void>(route).pipe(oMap(() => {}))
  }

  createProductCategory(category: ProductCategory) {
    const route = `/products/categories`
    return this.post<ProductCategoryData>(route, category)
  }

  updateProductCategory(category: ProductCategory): Observable<void> {
    const route = `/products/categories/${category.id}`
    return this.put<void>(route, category.toJSON()).pipe(oMap(() => {}))
  }

  deleteProductCategory(categoryId: string): Observable<void> {
    const route = `/products/categories/${categoryId}`
    return this.delete<void>(route).pipe(oMap(() => {}))
  }

  createLocation(location: Facility): Observable<Facility> {
    const route = `/clinic-settings/locations`
    return this.post<FacilityData>(route, location.toJSON()).pipe(
      oMap((resp) => new Facility(resp.data)),
    )
  }

  updateLocation(location: Facility): Observable<Facility> {
    const route = `/clinic-settings/locations/${location.id}`
    return this.put<FacilityData>(route, location.toJSON()).pipe(
      oMap((resp) => new Facility(resp.data)),
    )
  }

  deleteLocation(locationId: string): Observable<void> {
    const route = `/clinic-settings/locations/${locationId}`
    return this.delete<void>(route).pipe(oMap(() => {}))
  }

  prepareImport(input: { contentType: string, format: string, dataset: string }): Observable<{ url: string, object: string, contentType: string }> {
    const route = `/clinic-settings/import/prepare`
    const params = this.convertToParams(input)
    return this.get<any>(route, params).pipe(
      oMap((resp) => resp.data),
    )
  }

  getPaymentProviderAccountCreateUrl() {
    const route = '/clinic-settings/payment/accounts/authorize'
    const apiUrl = this.getApiUrl('/webhooks/stripe/token')
    const originUrl = this.getUrl('/settings/payment')
    const params = new HttpParams({
      fromObject: {
        ApiUrl: apiUrl,
        OriginUrl: originUrl,
      },
    })

    return this.get<{ url: string }>(route, params)
  }

  createTilledPaymentAccount() {
    const route = '/clinic-settings/payment-tilled/accounts'
    return this.post<any>(route, null).pipe(
      oMap((resp) => {
        return resp.data
      }),
    )
  }

  retrieveTilledPaymentAccount() {
    const route = '/clinic-settings/payment-tilled/accounts'
    return this.get<any>(route, null).pipe(
      oMap((resp) => {
        return resp.data
      }),
    )
  }

  submitPayment(data: object) {
    const route = '/transactions/pay'
    return this.post<any>(route, { ...data }).pipe(
      oMap((resp) => {
        return resp.data
      }),
    )
  }

  deletePaymentProviderAccount(): Observable<void> {
    const route = `/payment/accounts`
    return this.delete<void>(route).pipe(oMap(() => null))
  }

  getMessageTemplates(input: {
    includeCampaignTemplates?: boolean
    includeWorkflowTemplates?: boolean
    includeSystemTemplates?: boolean
  }): Observable<MessageTemplateModel[]> {
    const route = `/messages-templates`
    const params = this.convertToParams(input)
    return this.get<MessageTemplateData[]>(route, params).pipe(
      oMap((response) => {
        return response.data.map((template) => new MessageTemplateModel(template))
      }),
    )
  }

  getMessageTemplate(workflowId: string): Observable<MessageTemplateModel> {
    const route = `/messages-templates/${workflowId}`
    return this.get<MessageTemplateData>(route).pipe(
      oMap((response) => {
        return new MessageTemplateModel(response.data)
      }),
    )
  }

  createMessageTemplate(
    messageTemplate: MessageTemplateModel,
  ): Observable<MessageTemplateModel> {
    const route = `/messages-templates`
    return this.post<MessageTemplateData>(route, messageTemplate.toJSON()).pipe(
      oMap((response) => {
        return new MessageTemplateModel(response.data)
      }),
    )
  }

  updateMessageTemplate(model: MessageTemplateModel): Observable<void> {
    const route = `/messages-templates`
    return this.put<void>(route, model).pipe(oMap(() => {}))
  }

  deleteMessageTemplate(messageTemplateId: string): Observable<any> {
    const route = `/messages-templates/${messageTemplateId}`
    return this.delete<void>(route)
  }

  previewMessagesTemplate(options): Observable<any> {
    const route = `/messages-templates/preview`
    return this.post<any>(route, options).pipe(
      oMap((response) => {
        return response && response.data
      }),
    )
  }

  getSupportedMessageAttachments(workflowId: string) {
    const route = `/messages-templates/${workflowId}/attachments`
    return this.get<Workflow.Attachment[]>(route).pipe(
      oMap(toData),
    )
  }

  exportQuestions(intakeSection: string, format: 'csv' | 'json' = 'csv') {
    const route = `/clinic-settings/questions/csv`
    const params: any = { format }
    if (intakeSection) {
      params.leg = intakeSection
    }
    const httpParams = new HttpParams({ fromObject: params })
    return this.getCSV(route, httpParams)
  }

  getProcedureInfo(
    procedureCode: string,
    includeModifiers = false,
  ): Observable<Procedure | undefined> {
    const route = `/cms/procedures/${procedureCode}`
    const params = this.convertToParams({
      includeModifiers,
    })
    return this.get<ProcedureData>(route, params).pipe(
      oMap(
        (resp) => {
          return new Procedure(resp.data)
        },
        catchError((err: PatientPalRestError) => {
          if (err.status === NOT_FOUND) {
            return of()
          } else {
            return throwError(() => err)
          }
        }),
      ),
    )
  }

  getProcedureCodeSuggestions(
    procedureNames: string | string[],
  ): Observable<{ query: string; suggestions: ProcedureData[] }[]> {
    const route = `/cms/suggest`
    return this.post<any[]>(route, { q: procedureNames }).pipe(
      oMap((resp) => {
        return resp.data
      }),
    )
  }

  upsertProduct(product: ProductModel, meta: any) {
    if (product.id) {
      return this.updateProduct(product, meta)
    } else {
      return this.createProduct(product, meta)
    }
  }

  createProduct(product: ProductModel, meta: any) {
    const route = `/products`
    return this.post<ProductData>(route, {
      ...product.toJSON(),
      ...meta,
    })
  }

  updateProduct(product: ProductModel, meta: any) {
    const route = `/products/${product.id}`
    return this.put<ProductData>(route, {
      ...product.toJSON(),
      ...meta,
    })
  }

  patchProduct(product: string, data: any) {
    const route = `/products/${product}`
    return this.patch<ProductData>(route, data)
  }

  deleteProduct(productId: string) {
    const route = `/products/${productId}`
    return this.delete<void>(route)
  }

  getProduct(
    productId: string,
    options = { withPrices: false },
  ): Observable<ProductModel> {
    let params = new HttpParams()

    if (options.withPrices) {
      params = params.set('prices', 'true')
    }

    const route = `/products/${productId}`
    return this.get<ProductData>(route, params).pipe(
      oMap((resp) => {
        return new ProductModel(resp.data)
      }),
    )
  }

  createProductPrice(price: Record<string, any>) {
    const route = `/products/${price.productId}/prices`
    return this.post<ProductData>(route, price)
  }

  updateProductPrice(price: Record<string, any>) {
    const route = `/products/${price.productId}/prices/${price.id}`
    return this.put<ProductData>(route, price)
  }

  deleteProductPrice(price: Price) {
    const route = `/products/${price.productId}/prices/${price.id}`
    return this.delete<void>(route)
  }

  searchChargeDescriptionMaster(params: {
    type?: ChargeDescriptionMaster.TYPE,
    ids?: string[],
    cptCodes?: string[],
    cptCode?: string,
    chargeDescription?: string
  }) {
    const route = '/charge-description-master/search'

    return this.get<ChargeDescriptionMasterData[]>(
      route,
      this.convertToParams(params as any),
    ).pipe(
      oMap(toData),
    )
  }

  getBillingOverview() {
    const route = `/clinic-billing/overview`
    return this.get<any>(route)
  }

  getBillingEstimate() {
    const route = `/clinic-billing/estimate`
    return this.get<any>(route)
  }

  getBillingStatus() {
    const route = `/clinic-billing/status`
    return this.get<{
      openInvoices: number
      pastDue: boolean
      pastDueDays: number
    }>(route)
  }

  getProceduresEstimates(params: PagedParams) {
    return this.get<ProceduresEstimateItem[]>(
      '/procedures-estimate',
      this.convertToParams(params as any),
    )
  }

  getProceduresEstimate(id: string): Observable<ProceduresEstimate> {
    return this.get<ProceduresEstimateResponse>(`/procedures-estimate/${id}`).pipe(
      oMap(response => ({
        id: response.data.id,
        patient: new Patient(response.data.patient),
        products: response.data.products.map(p => new ProductModel(p)),
        eligibility: response.data.eligibility,
        estimate: response.data.estimate,
      })),
    )
  }

  updatePatientCard(card: any, patientId: string) : Observable<Card> {
    const route = `/payments/patients/${patientId}/payment-methods`
    return this.put<CardData>(route, {
      ...card,
    }).pipe(
      oMap((resp) => new Card(resp.data)),
    )
  }

  deletePatientCard(card: Card, patientId: string) {
    const route = `/payments/patients/${patientId}/payment-methods`
    const params = this.convertToParams({ id: card.id, type: card.provider })
    return this.delete<void>(route, { params })
  }

  getPatientAppointmentHistory(patientId: string, appointmentId?: string): Observable<AppointmentHistoryData[]> {
    const route = `/patients/${patientId}/appointmenthistory`
    const params = appointmentId ? this.convertToParams({ appointmentId }) : undefined
    return this.get<AppointmentHistoryData[]>(route, params).pipe(
      oMap((response) => {
        return map(response.data)
      }),
    )
  }
}
