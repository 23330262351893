import { BaseModel, BaseModelData, PatientSexes } from '@mmx/shared'
import { cloneDeep, extend, omit } from 'lodash'
import moment from 'moment'

import { AddressSchema } from '../schemas/address.schema'
import { CustomQuestionSchema } from '../schemas/custom-question.schema'

export interface IntakeData extends BaseModelData {
  patientId: string
  stage: string
}

export class IntakeModel extends BaseModel {
  patientId: string
  appointmentId: string
  givenName: string
  familyName: string
  address: AddressSchema
  phoneNumber: string
  email: string
  weight: string
  height: string
  sex: PatientSexes
  gender: string
  dob?: moment.Moment
  emergencyContactName: string
  emergencyContactPhoneNumber: string
  hasPCP: boolean
  pcpName: string
  pcpOffice: string
  pcpPhoneNumber: string
  pcpAddress: AddressSchema
  hasGuarantor: boolean
  guarantorDOB?: moment.Moment
  guarantorGivenName: string
  guarantorFamilyName: string
  guarantorPhoneNumber: string
  guarantorRelationship: string
  hasInsurance?: boolean
  insuranceCarrier: string
  insuranceNumber: string
  isPrimaryOnInsurance: boolean
  stage: Intake.STAGE
  statusTransitions?: { [key: string]: moment.Moment }
  customQuestions?: CustomQuestionSchema[]
  recentlyModifiedSections?: string[]
  recentlyUploadedFiles?: string[]
  sections?: string[]
  consented: { [key: string]: boolean }
  consents?: { id: string; title: string }[]
  confirmed?: { clinicianId: string; date: string }
  unsigned?: { clinicianId: string; date: string }
  submittedAt?: moment.Moment

  constructor(data: any) {
    super(data)

    if (!data.recentlyModifiedSections) {
      this.recentlyModifiedSections = []
    }

    if (!data.recentlyUploadedFiles) {
      this.recentlyUploadedFiles = []
    }

    extend(this, omit(data, ['updatedAt', 'createdAt', 'id']))

    this.dob =
      data.dob == null ? data.dob : this.transformDate(data.dob, false)
    this.guarantorDOB =
      data.guarantorDOB == null
        ? null
        : this.transformDate(data.guarantorDOB, false)
  }

  get date() {
    return this.updatedAt ? this.updatedAt.format('LL') : ''
  }

  get stageDisplay(): string {
    switch (this.stage) {
      case Intake.STAGE.DELIVERED:
        return 'Sent to Patient'
      case Intake.STAGE.PENDING:
        return 'Pending'
      // No intake should linger in submitted, it will hit the intake stream, be processed, and be moved to "Confirmed"
      case Intake.STAGE.SUBMITTED:
        return 'Processing submitted'
      // Confirmed now only means the intake was submitted by patient and it went through the intake stream
      case Intake.STAGE.CONFIRMED:
        return 'Submitted'
      case Intake.STAGE.TECH_CONFIRMED:
        return 'Signed off'
      case Intake.STAGE.TECH_REVIEW:
        return 'In Review'
      case Intake.STAGE.SYNCING:
        return 'Syncing'
      case Intake.STAGE.SYNCED:
        return 'Synced'
      default:
        return ''
    }
  }

  get text() {
    return `${this.stageDisplay} Intake on ${this.date}`
  }

  get url(): string {
    return `/patient/${this.patientId}/intake/${this.id}`
  }

  get isConfirmed(): boolean {
    return [
      Intake.STAGE.CONFIRMED,
      Intake.STAGE.TECH_CONFIRMED,
      Intake.STAGE.SYNCED,
      Intake.STAGE.SYNCING,
    ].includes(this.stage)
  }

  get isNotConfirmed() {
    return [
      Intake.STAGE.PENDING,
      Intake.STAGE.DELIVERED,
      Intake.STAGE.SUBMITTED,
      Intake.STAGE.TECH_REVIEW,
    ].includes(this.stage)
  }

  get isSignedOff(): boolean {
    return !!this.confirmed
  }

  toJSON() {
    const data: any = cloneDeep(this)

    if (this.dob) {
      data.dob = this.dob.format('YYYY-MM-DD')
    }

    if (this.guarantorDOB) {
      data.guarantorDOB = this.guarantorDOB.format('YYYY-MM-DD')
    }

    return data
  }
}

export namespace Intake {
  export enum STAGE {
    PENDING = 'P',
    DELIVERED = 'D',
    SUBMITTED = 'S',
    CONFIRMED = 'C',
    TECH_CONFIRMED = 'T',
    TECH_REVIEW = 'W',
    SYNCING = 'U',
    SYNCED = 'Y',
    NONE = 'N',
    ACKNOWLEDGED = 'A',
    NOT_REQUIRED = 'R',
  }
}
