import { SelectOption, SelectOptionGroup } from '@mmx/shared'

import { Campaign, CampaignNode } from '../models'

export const CampaignEventTriggers: SelectOptionGroup<Campaign.TRIGGER>[] = [
  {
    label: 'Appointments',
    options: [
      {
        value: Campaign.TRIGGER.APPOINTMENT_NEW,
        viewValue: 'Appointment scheduled',
        hint: 'Run when an appointment is scheduled',
      },
      {
        value: Campaign.TRIGGER.APPOINTMENT_RESCHEDULED,
        viewValue: 'Appointment rescheduled',
        hint: `Run when an appointment's start time is changed`,
      },
      {
        value: Campaign.TRIGGER.APPOINTMENT_CANCELLED,
        viewValue: 'Appointment cancelled',
        hint: 'Run when an appointment is cancelled',
      },
      {
        value: Campaign.TRIGGER.APPOINTMENT_COMPLETED,
        viewValue: 'Appointment service completed',
        hint: 'Run when an appointment is over',
      },
    ],
  },
  {
    label: 'Intakes',
    options: [
      {
        value: Campaign.TRIGGER.INTAKE_CONFIRMED,
        viewValue: 'Intake confirmed',
        hint: 'Run when an intake is submitted a patient',
      },
    ],
  },
]

export const CampaignNodeActionTypes: SelectOption<CampaignNode.ACTION>[] = [
  {
    value: CampaignNode.ACTION.MESSAGE_PATIENT,
    viewValue: 'Send message to patient',
  },
  {
    value: CampaignNode.ACTION.NOTIFY_CLINICIANS,
    viewValue: 'Send notification to employees',
  },
]
