export interface CampaignNodeData {
  id: string
  label: string
  type: CampaignNode.TYPE
  properties: Record<string, any> // Propiedades específicas del nodo
  connections: CampaignNodeConnection[] // Transiciones
}

export interface CampaignNodeConnection {
  targetNodeId: string // Nodo destino
  condition?: string // Condición para esta conexión (opcional)
}

export class CampaignNodeModel {
  id: string
  label: string
  type: CampaignNode.TYPE
  properties: Record<string, any>
  connections: CampaignNodeConnection[]

  constructor(data?: CampaignNodeData) {
    this.id = data?.id || ''
    this.type = data?.type || CampaignNode.TYPE.ACTION
    this.properties = data?.properties || {}
    this.connections = data?.connections || []
  }
}

export namespace CampaignNode {
  export enum TYPE {
    ACTION = 'action',
    GOTO = 'goto',
    BRANCH = 'branch',
    DELAY = 'delay',
    TRIGGER = 'trigger',
    END = 'end',
  }
  export enum ACTION {
    MESSAGE_PATIENT = 'patient.message',
    NOTIFY_CLINICIANS = 'clinicians.notify',
  }
}
