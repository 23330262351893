import { Appointment } from '../models'

export function appointmentStatusText(status: Appointment.STATUS): string {
  switch (status) {
    case Appointment.STATUS.OPEN:
      return 'statuses.appointments.open'
    case Appointment.STATUS.HOLD:
      return 'statuses.appointments.hold'
    case Appointment.STATUS.ARRIVED:
      return 'statuses.appointments.patient-arrived'
    case Appointment.STATUS.CHECKING_IN:
      return 'statuses.appointments.patient-checking-in'
    case Appointment.STATUS.CHECKED_IN:
      return 'statuses.appointments.patient-checking-in-awaiting'
    case Appointment.STATUS.IN_PROGRESS:
      return 'statuses.appointments.service-in-progress'
    case Appointment.STATUS.COMPLETED:
      return 'statuses.appointments.service-completed'
    case Appointment.STATUS.CANCELLED:
      return 'statuses.appointments.cancelled'
    case Appointment.STATUS.RESCHEDULE:
      return 'statuses.appointments.needs-rescheduling'
    case Appointment.STATUS.ABORTED:
      return 'statuses.appointments.aborted'
    case Appointment.STATUS.NO_SHOW:
      return 'statuses.appointments.no-show'
    default:
      return 'appointment.appointment-status.open'
  }
}
