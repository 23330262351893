import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Clinician, RestService } from '@mmx/shared'
import * as Sentry from '@sentry/angular'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import {
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  shareReplay,
} from 'rxjs/operators'

import { NETWORK_FAILURE, UNAUTHENTICATED } from '../data'
import { ClinicService } from './clinic.service'
import { PermissionsService } from './permissions.service'

const LANGUAGE_STORAGE_CODE = 'ppc-language'
// deepcode ignore HardcodedNonCryptoSecret: this is not a secret or token itself, it's the variable name for the token
const TOKEN_STORAGE_KEY = 'ppc-token'

class Authorization {
  isLoggedIn = false
  _isClinician = false

  get isClinician() {
    return this._isClinician
  }

  set isClinician(val: boolean) {
    this.isLoggedIn = true
    this._isClinician = true
  }
}

export interface AuthResponse {
  session: {
    token: string
    valid?: boolean
    step?: string
  }

  user: {
    type: string // 'clinician' or 'patient'
    id: string
    email?: string
    name?: string
    title?: string
  }

  clinic: {
    id: string
  }

  permissions: string[]
}

interface PasswordResetResponse {
  messageId: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: Clinician
  public changes: Observable<Clinician>
  private userChange = new BehaviorSubject<Clinician>(null)
  private authorization: Authorization
  private interval: any

  constructor(
    private permissionsSvc: PermissionsService,
    private restService: RestService,
  ) {
    this.changes = this.userChange.asObservable().pipe(shareReplay(1))

    this.changes
      .pipe(
        filter((user) => user != null),
        distinctUntilKeyChanged('id'),
      )
      .subscribe((user) => {
        Sentry.setUser({
          id: user.id,
          email: user.email,
        })
      })
  }

  launchRenewSession() {
    if (!this.interval) {
      this.interval = setInterval(this.renewSession.bind(this), 10 * 60 * 1000) // 10 minutes
    }
  }

  isUserLoggedIn() {
    return this.authorization && this.authorization.isLoggedIn
  }

  hasSessionToken() {
    return typeof this.sessionToken === 'string' && this.sessionToken.length > 0
  }

  checkAuthorization(): boolean {
    return this.authorization && this.authorization.isClinician
  }

  updateFromAuthRequest(data: AuthResponse) {
    if (!data) {
      return
    }

    if (data.session && data.session.token) {
      this.sessionToken = data.session.token
    }

    if (data.user) {
      this.authorization = new Authorization()

      if (data.user.type === 'clinician') {
        const clinician = new Clinician(data.user as any)
        this.user = clinician
        this.userChange.next(clinician)
        this.authorization.isClinician = true
      }

      this.updatePermissions(data.permissions)
    }
  }

  loginClinician(body: {
    clinic: string
    mfaDevice?: string
    mfaToken?: string
    email: string
    password: string
    rememberMe?: boolean
  }): Observable<any> {
    return this.restService.post<any>('/auth/login', body).pipe(
      map((result) => {
        if (result.data && result.data.session) {
          const clinician = new Clinician(result.data.user)
          this.reset()
          this.sessionToken = result.data.session.token
          ClinicService.clinicId = result.data.clinic.id
          this.user = clinician
          this.userChange.next(clinician)
          this.authorization.isClinician = true
          this.updatePermissions(result.data.permissions)
        }

        return result.data
      }),
    )
  }

  checkClinics(body: {
    email: string,
    password: string
  }) {
    return this.restService.post<any>('/auth/check', body)
  }

  forgotPassword(email: string): Observable<void> {
    const clinic = ClinicService.clinicId
    const body: any = { email }

    if (clinic) {
      body.clinic = clinic
    }

    return this.restService
      .post<PasswordResetResponse>('/auth/forgot', body)
      .pipe(
        map(() => {
          return
        }),
      )
  }

  resetPassword(
    email: string,
    nonce: string,
    password: string,
  ): Observable<void> {
    const body = {
      email,
      nonce,
      password,
    }

    return this.restService
      .post<PasswordResetResponse>('/auth/reset', body)
      .pipe(
        map(() => {
          return
        }),
      )
  }

  logout() {
    this.reset()

    if (this.interval) {
      clearInterval(this.interval)
    }

    // send the user to the login page, this causes a reload
    location.href = '/auth/login'

    // clears the user from Sentry
    Sentry.setUser(null)
  }

  get sessionToken() {
    return localStorage.getItem(TOKEN_STORAGE_KEY)
  }

  set sessionToken(value: string) {
    localStorage.setItem(TOKEN_STORAGE_KEY, value)
  }

  get languageCode() {
    return localStorage.getItem(LANGUAGE_STORAGE_CODE)
  }

  set languageCode(value: string) {
    localStorage.setItem(LANGUAGE_STORAGE_CODE, value)
  }

  reset() {
    this.authorization = new Authorization()
    localStorage.removeItem(TOKEN_STORAGE_KEY)
  }

  private renewSession() {
    if (this.hasSessionToken() && this.isUserLoggedIn()) {
      let subscription: Subscription | undefined
      subscription = this.userChange.pipe(first()).subscribe((user) => {
        if (subscription) {
          subscription.unsubscribe()
        }

        this.restService
          .get<AuthResponse>('/auth', undefined, {
            headers: new HttpHeaders({
              Authorization: this.sessionToken,
              'WWW-Clinic-ID': user.clinicId,
            }),
          })
          .subscribe(
            (result) => {
              if (subscription) {
                subscription.unsubscribe()
              }

              this.updateFromAuthRequest(result.data)
            },
            (err: HttpErrorResponse) => {
              if (
                err.status === UNAUTHENTICATED ||
                err.status === NETWORK_FAILURE
              ) {
                this.logout()
              } else {
                throw err
              }
            },
          )
      })
    }
  }

  private updatePermissions(permissions: string[]) {
    this.permissionsSvc
      .setPermissions(permissions)
      .setUser(this.user)
  }
}
