import { Injectable } from '@angular/core'
import { StripeElementLocale } from '@stripe/stripe-js'
import { environment } from 'environments/environment'
import { map } from 'rxjs'

import { ClinicService } from './clinic.service'
import { I18nService } from './i18n.service'

@Injectable({
  providedIn: 'root',
})
export class AppService {
  site: 'patientpal' | 'healthlogix' | 'expeditor' = 'patientpal'
  name = 'Med-Metrix'
  slogan = 'From Clipboard to Claim'
  company = 'Med-Metrix LLC'
  privacyUrl = 'https://www.patientpal.com/privacy.html'
  tosUrl = 'https://www.patientpal.com/provider-terms.html'
  supportUrl = 'https://help.patientpal.com'
  supportEmail = 'customerservice@med-metrix.com'
  supportPhone = '+18887281368'
  defaultTheme = 'blue'
  primaryDomain = '.patientpal.com'
  logoSrc: string = null

  currentTheme: string
  availableThemes = ['blue', 'dark', 'indigo', 'orange', 'purple']

  currentLanguage: StripeElementLocale = 'en'
  availableLanguages$ = this.clinicService.clinic$.pipe(
    map(clinic => clinic.languages),
  )

  favicon: HTMLLinkElement = document.querySelector('#favicon')
  favicon16: HTMLLinkElement = document.querySelector('#favicon16')
  favicon32: HTMLLinkElement = document.querySelector('#favicon32')
  faviconApple: HTMLLinkElement = document.querySelector('#faviconApple')

  get savedTheme(): string | null {
    try {
      return localStorage.getItem('ppal-theme') || null
    } catch (e) {
      // Safari private mode
      return null
    }
  }

  set savedTheme(theme: string | null) {
    try {
      if (theme) {
        localStorage.setItem('ppal-theme', theme)
      } else {
        localStorage.removeItem('ppal-theme')
      }
    } catch (e) {
      // Safari private mode
    }
  }

  constructor(private clinicService: ClinicService, private i18n: I18nService) {
    this.detect()

    this.clinicService.clinic$.subscribe(() => {
      this.detect()
      this.currentLanguage = this.i18n.currentLang
    })
  }

  detect() {
    const type = this.clinicService.clinic ? this.clinicService.clinic.type : ''

    // patientpal:whitelabel
    // if this is *.expeditor.app in production, or for development, anything containing "expeditor" (update /etc/hosts file)
    if (
      type === 'expeditor' ||
      location.hostname.includes('.expeditor.app') ||
      (environment.env === 'dev' && location.hostname.includes('expeditor'))
    ) {
      this.site = 'expeditor'
      this.name = 'Xtrac'
      this.slogan = 'Performance'
      this.company = 'Expeditor Systems'
      this.defaultTheme = 'indigo'
      this.logoSrc = '/assets/images/expeditor.png'
      this.favicon.href = '/assets/images/clients/expeditor/favicon.ico'
      this.favicon16.href = '/assets/images/clients/expeditor/favicon-16x16.png'
      this.favicon32.href = '/assets/images/clients/expeditor/favicon-32x32.png'
      this.faviconApple.href =
        '/assets/images/clients/expeditor/apple-icon-180x180'
      // this.primaryDomain = '.expeditor.app'
    } else if (
      type === 'healthlogix' ||
      location.hostname.includes('healthlogix')
    ) {
      this.site = 'healthlogix'
      this.name = 'Health Logix'
      this.slogan = 'Efficiency Through Automation'
      this.company = 'Health Logix'
      this.defaultTheme = 'orange'
      this.logoSrc = '/assets/images/healthlogix.png'
      this.supportPhone = '+16153920061'
      this.favicon.href = '/assets/images/clients/healthlogix/favicon.ico'
      this.favicon16.href =
        '/assets/images/clients/healthlogix/favicon-16x16.png'
      this.favicon32.href =
        '/assets/images/clients/healthlogix/favicon-32x32.png'
      this.faviconApple.href =
        '/assets/images/clients/healthlogix/apple-icon-180x180'
    }

    if (
      this.savedTheme != null &&
      this.availableThemes.includes(this.savedTheme)
    ) {
      this.setTheme(this.savedTheme, false)
    } else {
      this.setTheme(this.defaultTheme, false)
    }
  }

  setTheme(theme: string, userSet = true) {
    const html = document.querySelector('html')

    if (this.currentTheme == null) {
      html.classList.remove(`theme-${this.currentTheme}`)
    }

    html.classList.forEach((className) => {
      if (className.startsWith('theme-')) {
        html.classList.remove(className)
      }
    })

    this.currentTheme = theme

    if (userSet) {
      this.savedTheme = theme
    }

    html.classList.add(`theme-${theme}`)
  }

  setTranslation(lang: StripeElementLocale) {
    this.i18n.setLang(lang)
    this.currentLanguage = lang
  }
}
