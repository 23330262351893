import { BaseModel, BaseModelData, ModelDate } from '@mmx/shared'
import moment from 'moment'

import { Worklist, WorklistData } from './worklist.model'

export interface WorkItemData extends BaseModelData {
  worklistId?: string
  workList?: WorklistData
  referenceType: Worklist.REFERENCE_TYPE
  referenceId?: string
  patientId?: string
  appointmentId?: string
  weight?: number
  metadata?: Record<string, any>
  files?: WorkItemFile[]
  status: WorkItem.STATUS
  snoozedUntil?: ModelDate
}

export interface WorkItemFile {
  bucket: string,
  object: string,
  name?: string,
  pages?: number,
  size?: number,
  extension: string, // .pdf
  signedUrl?: string,
  signedBlocksUrl?: string,
}

export class WorkItemModel extends BaseModel {
  worklistId?: string
  referenceType: Worklist.REFERENCE_TYPE
  referenceId?: string
  patientId?: string
  appointmentId?: string
  weight?: number
  metadata?: Record<string, any>
  files?: WorkItemFile[]
  status: WorkItem.STATUS
  snoozedUntil?: moment.Moment

  constructor(data?: WorkItemData) {
    super(data, true)

    if (data.snoozedUntil) {
      this.snoozedUntil = this.transformDate(data.snoozedUntil)
    }
  }

  get url() {
    switch (this.referenceType) {
      case 'appointment':
        return ['/patient', this.patientId, 'appointment', this.appointmentId].join('/')
      case 'patient':
        return ['/patient', this.patientId].join('/')
      case 'arbitrary':
      case 'file':
        return ['/worklist', this.worklistId, 'items', this.id].join('/')
    }
  }

  toJSON() {
    return {
      id: this.id,
      worklistId: this.worklistId,
      referenceType: this.referenceType,
      referenceId: this.referenceId,
      patientId: this.patientId,
      appointmentId: this.appointmentId,
      weight: this.weight,
      metadata: this.metadata,
      files: this.files,
      status: this.status,
      snoozedUntil: this.snoozedUntil?.toISOString(),
    }
  }
}

export namespace WorkItem {
  export enum STATUS {
    /**
     * Item has been added to the Work List but work has not started
     */
    NEW = 'N',
    /**
     * Item is pre-assigned to a Clinician, but work has not started
     */
    ASSIGNED = 'A',
    /**
     * Item is snoozed
     */
    SNOOZED = 'D',
    /**
     * Item has been assigned and is being worked now
     */
    IN_PROGRESS = 'P',
    /**
     * Item has been marked completed by the assignee
     */
    FINISHED = 'F',
    /**
     * Item has been escalated to another worklist
     */
    ESCALATED = 'E',
    /**
     * Item was present in the work list but was then removed due to no longer
     * being qualified after the item has progressed past New.
     */
    CANCELLED = 'C',
  }

  export enum MetaFields {
    /**
     * Used to reference the original worklist item when moving a file from one worklist to another
     */
    ORIGINAL_ITEM = 'ORIGINAL_ITEM',
  }
}
