import { BaseModel, BaseModelData, Facility, FacilityData, ModelDate, Patient, PatientData } from '@mmx/shared'
import { extend, isArrayLike, isObjectLike, map } from 'lodash'
import moment from 'moment-timezone'

import { ProductData, ProductModel } from './product.model'

export interface AppointmentRecData extends BaseModelData {
  eligibilityDate?: ModelDate
  startAt: ModelDate
  location?: FacilityData
  locationId: string
  notes?: string
  patient?: PatientData
  patientId: string
  products?: (string | number | ProductData)[]
  productCategories?: string[]
  status?: AppointmentRec.STATUS
  clinicianId?: string
}

const timeFormat = 'LT' // 'h:mm A'
export class AppointmentRecModel extends BaseModel {
  eligibilityDate?: moment.Moment
  startAt: moment.Moment
  locationId: string
  location?: Facility
  notes: string
  patientId: string
  patient?: Patient
  productIds: string[] = []
  products: ProductModel[] = []
  productCategories: string[] = []
  status: AppointmentRec.STATUS = AppointmentRec.STATUS.OPEN
  clinicianId?: string

  constructor(data?: AppointmentRecData) {
    super(data)

    if (data) {
      this.patientId = data.patientId
      this.locationId = data.locationId
      this.startAt = this.transformDate(data.startAt)
      this.eligibilityDate = this.transformDate(data.eligibilityDate)
      this.status = data.status as AppointmentRec.STATUS
      this.notes = data.notes
      this.productCategories = data.productCategories || []

      if (data.patient) {
        this.patient = new Patient(data.patient)
      }

      if (data.location) {
        this.location = new Facility(data.location)
      }

      if (isArrayLike(data.products) && data.products.length > 0) {
        if (isObjectLike(data.products[0])) {
          this.products = map(data.products, (productData) => new ProductModel(productData as ProductData))
          this.productIds = map(this.products, (product) => product.id)
        } else {
          this.productIds = data.products as string[]
        }
      }
    }
  }

  get date(): string {
    if (this.startAt) {
      return this.startAt.format('MM/DD/YYYY')
    } else {
      return ''
    }
  }

  getTimeTooltip(facility?: Facility): string | undefined {
    const startAt = this.startAt.clone()
    const format = timeFormat
    const utcTime = startAt.clone().utc().format(format)
    const localTime = startAt.clone().local().format(format)
    let facilityTime = ''

    if (facility?.timezone) {
      facilityTime = startAt.tz(facility.timezone).format(format)
    } else if (this.location?.timezone) {
      facilityTime = startAt.tz(this.location.timezone).format(format)
    }

    if (facilityTime && facilityTime !== localTime && localTime !== utcTime) {
      return `Facility time: ${facilityTime}, local time: ${localTime}`
    }
  }

  toJSON(): any {
    let products: any = this.productIds

    if (this.products.length > 0) {
      products = this.products.map((product) => product.id)
    }

    return extend(super.toJSON(), {
      patient: this.patientId,
      locationId: this.locationId,
      startAt: this.startAt.toISOString(),
      eligibilityDate: this.eligibilityDate.toISOString(),
      status: this.status,
      notes: this.notes,
      products,
    })
  }

  asJSON(action: string): any {
    const values = {
      id: this.id,
      startAt: this.startAt.isValid() ? this.startAt : '',
      eligibilityDate: this.eligibilityDate.isValid() ? this.eligibilityDate : '',
      products: this.products || [],
      notes: this.notes || '',
      location: this.locationId ? this.locationId : '',
      action,
    }

    return values
  }
}

export namespace AppointmentRec {
  export enum STATUS {
    OPEN = 'O',
    CANCELLED = 'C',
  }
}
