import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'

import { AppConfirmComponent } from './app-confirm.component'
import { AppConfirmService } from './app-confirm.service'
import { ConfirmClickDirective } from './app-confirm-click.directive'

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [
    AppConfirmComponent,
    ConfirmClickDirective,
  ],
  declarations: [
    AppConfirmComponent,
    ConfirmClickDirective,
  ],
  providers: [AppConfirmService],
})
export class AppConfirmModule {}
