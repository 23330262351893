import { AddressSchema, BaseModel, BaseModelData, Clinician, ClinicianData, ModelDate, Patient, PatientNameSchema, TaxRateData } from '@mmx/shared'
import { cloneDeep, extend, isEmpty, round } from 'lodash'
import moment from 'moment'

import { Breadcrumb } from '../data'
import { AppointmentEstimateSchema } from '../schemas'
import { AppointmentData, AppointmentModel } from './appointment.model'

export interface InvoiceLineItem {
  // order: number // unique number
  description: string
  amount: number
  expectedAmount?: number
  currency?: string
  discountable?: boolean // defaults to "true", meaning discounts added by patient will discount this line item
  period?: {
    start?: Date
    end?: Date
  }
  quantity?: number // defaults to "1"
  unit_amount?: number
  // taxRates: TaxRate[]
  proration?: boolean // whether this is a proration
}

export interface InvoiceTaxAmount {
  amount: number // The amount, in cents, of the tax.
  inclusive?: boolean // Whether this tax amount is inclusive or exclusive.
  percentage?: number
  description?: string
}

export interface InvoicePlan {
  total?: number
  subtotal?: number
  interest?: number
  fee?: number
  feePercentage?: number
  feeAmount?: number
  months?: number
  monthlyAmount?: number
  firstPayment?: number
  paymentMethodId?: string
  nextPaymentAt?: Date
  startAt?: Date
  endAt?: Date
}

export interface InvoiceDiscount {
  percent: number
  amount: number
  total: number
}

interface InvoiceTemplate {
  description?: string
  footer?: string
  postPayment?: string
  due?: number
  periodStart?: ModelDate
  periodEnd?: ModelDate
}

interface InvoiceOptions {
  enablePaymentPlan?: boolean
  enablePromptPayDiscount?: boolean
}

export interface InvoiceData extends BaseModelData {
  shortId?: string
  paid?: boolean
  patientId: string
  appointmentId?: string
  lineItems: InvoiceLineItem[]
  status?: Invoice.STATUS
  paymentOption?: Invoice.PAYMENT_OPTION
  paymentProvider?: Invoice.PAYMENT_PROVIDER
  plan?: InvoicePlan
  amountDue?: number
  amountPaid?: number
  amountRemaining?: number
  attemptCount?: number
  subtotal: number
  tax?: number
  taxAmount?: number
  taxAmounts?: InvoiceTaxAmount[]
  feeDue?: number
  feePaid?: number
  feeRemaining?: number
  total: number
  collectionMethod?: Invoice.COLLECTION_METHOD
  currency?: string
  discount?: InvoiceDiscount
  nextPaymentAttempt?: ModelDate
  autoAdvance?: boolean
  defaultPaymentMethod?: string
  transactionId?: string
  customer?: {
    name?: PatientNameSchema
    email?: string
    address?: AddressSchema
    phone?: string
    taxExempt?: boolean
  }
  estimate?: AppointmentEstimateSchema
  template?: InvoiceTemplate
  options?: InvoiceOptions
  voidReason?: string
  patient?: Patient
  appointment?: AppointmentData
  dueDate?: ModelDate
  clinician?: ClinicianData
}

export class InvoiceModel extends BaseModel implements Breadcrumb {
  paid: boolean
  patientId: string
  patient?: Patient
  appointmentId?: string
  lineItems: InvoiceLineItem[]
  status: Invoice.STATUS
  paymentOption?: Invoice.PAYMENT_OPTION
  paymentProvider?: Invoice.PAYMENT_PROVIDER
  plan?: InvoicePlan
  amountDue: number
  amountPaid: number
  amountRemaining: number
  attemptCount: number
  subtotal: number
  tax: number
  taxAmount?: number
  taxAmounts: InvoiceTaxAmount[]
  feeDue?: number
  feePaid?: number
  feeRemaining?: number
  total: number
  currency: string
  discount?: InvoiceDiscount
  estimate: AppointmentEstimateSchema
  template: InvoiceTemplate
  collectionMethod: Invoice.COLLECTION_METHOD
  options: InvoiceOptions
  appointment?: AppointmentModel
  dueDate?: moment.Moment
  clinician?: Clinician

  get dueDays(): number {
    return this.dueDate != null ? this.dueDate.diff(moment.utc(), 'days') : 0
  }

  get pastDue(): boolean {
    if (this.paid) {
      return false
    }

    return this.dueDate != null && this.dueDate.isBefore(moment.utc(), 'day')
  }

  get pastDueDays(): number {
    return this.dueDate != null ? moment.utc().diff(this.dueDate, 'days') : 0
  }

  get exclusiveTaxes() {
    return this.taxAmounts.filter((t) => !t.inclusive)
  }

  get inclusiveTaxes() {
    return this.taxAmounts.filter((t) => t.inclusive)
  }

  set taxes(taxes: TaxRateData[]) {
    this.taxAmounts = taxes.map((tax) => {
      return {
        amount: Number(((tax.percentage * this.subtotal) / 100).toFixed()),
        inclusive: tax.inclusive,
        percentage: tax.percentage,
        description: tax.label,
      }
    })

    if (this.taxAmounts.length > 0) {
      // totalPercentage starts at 100, then adds all the percentages of inclusive taxes, so you'll get a number ABOVE
      // 100… if there is a VAT tax of 14%, this will become 114.
      const totalPercentage = this.taxAmounts
        .filter((t) => t.inclusive)
        .map((t) => t.percentage ?? 0)
        .reduce((a, b) => a + b, 100)

      // now you calculate the price per percentage
      const pricePerPercent = this.subtotal / totalPercentage
      // now manipulate the subtotal to artificially decrease it
      this.subtotal = round(pricePerPercent * 100, 2)
      this.tax = round(pricePerPercent * (totalPercentage - 100), 2)

      // Assign tax amount and update tax for taxes exclusive
      this.taxAmounts
        .filter((t) => t.percentage != null)
        .forEach((tax) => {
          const rate = tax.percentage / 100
          tax.amount = round(this.subtotal * rate, 2)
          if (!tax.inclusive) {
            this.tax = round(this.tax + tax.amount, 2)
          }
        })
    } else {
      this.tax = 0
    }
  }

  constructor(data: InvoiceData) {
    super(data)

    if (data) {
      this.shortId = data.shortId || (data.id ? data.id.substr(0, 8) : '')
      this.paid = data.paid || false
      this.patientId = data.patientId
      this.appointmentId = data.appointmentId
      this.lineItems = data.lineItems || []
      this.status = data.status || Invoice.STATUS.DRAFT
      this.paymentOption = data.paymentOption
      this.paymentProvider = data.paymentProvider
      this.plan = data.plan
      this.amountDue = data.amountDue
      this.amountPaid = data.amountPaid
      this.amountRemaining = data.amountRemaining
      this.attemptCount = data.attemptCount || 0
      this.subtotal = data.subtotal
      this.tax = data.tax
      this.taxAmount = data.taxAmount
      this.taxAmounts = data.taxAmounts || []
      this.feeDue = data.feeDue
      this.feePaid = data.feePaid
      this.feeRemaining = data.feeRemaining
      this.total = data.total
      this.currency = data.currency
      this.discount = data.discount
      this.estimate = data.estimate
      this.dueDate = this.transformDate(data.dueDate, false)
      if (isEmpty(this.estimate)) {
        this.estimate = undefined
      }
      this.template = data.template || {
        due: 30,
      }
      this.collectionMethod = data.collectionMethod
      this.options = data.options || {}

      if (data.patient) {
        this.patient = new Patient(data.patient)
      }
      if (data.appointment) {
        this.appointment = new AppointmentModel(data.appointment)
      }
      if (data.clinician) {
        this.clinician = new Clinician(data.clinician)
      }
    }
  }

  get text(): string {
    return `Invoice ${this.shortId || ''}`
  }

  get url(): string {
    return `/patient/${this.patientId}/invoices/${this.id}`
  }

  get statusDisplay(): string {
    switch (this.status) {
      case Invoice.STATUS.DRAFT:
        return 'common.draft'
      case Invoice.STATUS.VOID:
        return 'common.voided'
      case Invoice.STATUS.HOLD:
        return 'common.hold'
      case Invoice.STATUS.INTERIM:
        return 'common.interim'
      case Invoice.STATUS.CONFIRMED:
        return 'common.confirmed'
      case Invoice.STATUS.UNCOLLECTABLE:
        return 'common.uncollectable'
      case Invoice.STATUS.PAYING:
        return 'common.on-payment-plan'
      case Invoice.STATUS.PARTIAL:
        return 'common.partially-paid'
      case Invoice.STATUS.PAID:
        return 'common.paid'
    }
    return ''
  }

  get showDetails() {
    return this.status !== Invoice.STATUS.HOLD && !this.isPayed && this.amountRemaining > 0
  }

  get isPayed(): boolean {
    return this.status === Invoice.STATUS.PAID
  }

  get nextMonthlyPayment() {
    if (this.plan && this.plan.startAt) {
      const day = moment().date()
      const startAt = moment(this.plan.startAt)
      const endAt = moment(this.plan.endAt)
      const dayPlan = startAt.date()
      // If it finished
      if (endAt.diff(moment()) < 0) {
        return
      }

      if (day < dayPlan) {
        return moment().date(dayPlan).format()
      } else {
        return moment().date(dayPlan).add(1, 'month').format()
      }
    }

    return
  }

  toJSON() {
    const data = extend(super.toJSON(), {
      paid: this.paid,
      patientId: this.patientId,
      appointmentId: this.appointmentId,
      lineItems: this.lineItems.map((lineItem) => {
        const item = cloneDeep(lineItem)
        // if (lineItem.taxRates && lineItem.taxRates.length > 0) {
        //   item.taxRates = lineItem.taxRates.map()
        // }
        return item
      }),
      status: this.status,
      paymentOption: this.paymentOption,
      paymentProvider: this.paymentProvider,
      plan: this.plan,
      amountDue: this.amountDue,
      amountPaid: this.amountPaid,
      amountRemaining: this.amountRemaining,
      attemptCount: this.attemptCount || 0,
      subtotal: this.subtotal,
      tax: this.tax,
      taxAmount: this.taxAmount,
      taxAmounts: this.taxAmounts,
      feeDue: this.feeDue,
      feePaid: this.feePaid,
      feeRemaining: this.feeRemaining,
      total: this.total,
      currency: this.currency,
      estimate: this.estimate,
      template: this.template,
      collectionMethod: this.collectionMethod,
      dueDate: this.dueDate.format('YYYY-MM-DD'),
    })

    return data
  }
}

export namespace Invoice {
  export enum STATUS {
    DRAFT = 'D',
    VOID = 'V',
    HOLD = 'H',
    INTERIM = 'I',
    CONFIRMED = 'C',
    UNCOLLECTABLE = 'U',
    PAYING = 'Y',
    PARTIAL = 'S',
    PAID = 'P',
  }

  export enum PAYMENT_OPTION {
    PLAN = 'P',
    FULL = 'F',
  }

  export enum COLLECTION_METHOD {
    UPCOMING = 'U', // wait until the patient's appointment
    CHARGE = 'C', // charge the patient using a saved payment method
    SEND = 'S', // send to the patient immediately
  }

  export enum PAYMENT_PROVIDER {
    STRIPE = 'stripe',
    TILLED = 'tilled',
  }
}
