import { SelectOption } from '@mmx/shared'

import { Appointment } from '../models/appointment.model'

export const PaymentStatuses: SelectOption<string>[] = [
  {
    value: Appointment.PAYMENT_STATUS.NONE,
    viewValue: 'statuses.payments.none',
  },
  {
    value: Appointment.PAYMENT_STATUS.CONFIRMED,
    viewValue: 'statuses.payments.confirmed',
  },
  {
    value: Appointment.PAYMENT_STATUS.ON_FILE,
    viewValue: 'statuses.payments.method-on-file',
  },
  {
    value: Appointment.PAYMENT_STATUS.CHARGEABLE,
    viewValue: 'statuses.payments.chargeable',
  },
  {
    value: Appointment.PAYMENT_STATUS.PARTIAL,
    viewValue: 'statuses.payments.partial',
  },
  {
    value: Appointment.PAYMENT_STATUS.FULL,
    viewValue: 'statuses.payments.full',
  },
  {
    value: Appointment.PAYMENT_STATUS.REFUND_DUE,
    viewValue: 'statuses.payments.refund-due',
  },
]
