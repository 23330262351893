import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthorizationData, AuthorizationHistory, AuthorizationModel, AvailityServiceReview } from 'app/core/models'
import { map, Observable } from 'rxjs'

import { AuthenticatedRestService } from '../auth-rest.service'
import { SaveAuthorizationParams } from './models'

@Injectable({
  providedIn: 'root',
})
export class AppointmentAuthorizationService {
  constructor(private api: AuthenticatedRestService) {}

  public getAuthorizationList(patientId?: string): Observable<AuthorizationModel[]> {
    let route = `/authorizations`
    let params

    if (patientId) {
      params = new HttpParams({
        fromObject: {
          patientId,
        },
      })
    }

    return this.api.get<AuthorizationData[]>(route, params)
      .pipe(
        map(({ data }) => data.map((authorizationData) => new AuthorizationModel(authorizationData))),
      )
  }

  public getAuthorization(id: string, appointmentId: string) {
    const route = `/authorizations/${id}`
    const params = new HttpParams({
      fromObject: {
        appointmentId,
      },
    })

    return this.api.get<AuthorizationData>(route, params).pipe(
      map(({ data }) => new AuthorizationModel(data)),
    )
  }

  public createAuthorization(params: SaveAuthorizationParams) {
    const route = '/authorizations'

    return this.api.post<AuthorizationData>(route, params)
      .pipe(
        map(({ data }) => new AuthorizationModel(data)),
      )
  }

  public updateAuthorization(authorizationId: string, params: SaveAuthorizationParams) {
    const route = '/authorizations'

    return this.api.put<AuthorizationData>(route, {
      ...params,
      id: authorizationId,
    })
      .pipe(
        map(({ data }) => new AuthorizationModel(data)),
      )
  }

  public deleteAuthorization(authorizationId: string) {
    const route = `/authorizations/${authorizationId}`
    return this.api.delete(route)
  }

  public bulkCreateAuthorizations(
    appointmentId: string,
    carrierLookupId: string,
    patientInsuranceId: string,
    serviceReviews: AvailityServiceReview.Review[],
  ) {
    const route = '/authorizations/bulk'

    return this.api.post<AuthorizationData[]>(route, {
      appointmentId,
      carrierLookupId,
      patientInsuranceId,
      serviceReviews,
    })
      .pipe(
        map(({ data }) => data.map((authorizationData) => new AuthorizationModel(authorizationData))),
      )
  }

  public getAuthHistory(authId: string) {
    return this.api.get<{ histories: AuthorizationHistory[], appointments: { id: string, startAt: string }[] }>(`/authorizations/${authId}/history`).pipe(
      map(({ data }) => data),
    )
  }

  public getAttachments(authId: string) {
    return this.api.get(`/authorizations/${authId}/attachments`)
  }

  public prepareUpload(authId: string, params: { contentType: string, fileName: string, documentType: string }) {
    return this.api.post(`/authorizations/${authId}/prepare-upload`, params)
  }
}
