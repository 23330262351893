import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-confirm',
  templateUrl: './app-confirm.component.html',
  styleUrls: ['./app-confirm.component.scss'],
  standalone: false,
})
export class AppConfirmComponent implements AfterViewInit {
  @ViewChild('confirmButton') confirmButtonEl: ElementRef

  constructor(
    public dialogRef: MatDialogRef<AppConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngAfterViewInit() {
    if (this.confirmButtonEl && this.confirmButtonEl.nativeElement) {
      this.confirmButtonEl.nativeElement.focus()
    }
  }
}
