import { APPOINTMENT_REQUEST_STATUS, AppointmentRequest as SharedAppointmentRequest, AppointmentRequestData as SharedAppointmentRequestData, SchedulingQuestion } from '@mmx/shared'

import { AppointmentData, AppointmentModel } from './appointment.model'
import { ProductData, ProductModel } from './product.model'
import { ShoppableServiceData, ShoppableServiceModel } from './shoppable-service.model'

export interface SchedulingQuestionResponse {
  question: SchedulingQuestion
  answer: string | number | boolean
}

export interface AppointmentRequestData extends SharedAppointmentRequestData {
  products?: ProductData[]
  appointment?: AppointmentData
  shoppableService?: ShoppableServiceData
  schedulingQuestions?: SchedulingQuestionResponse[]
}

export class AppointmentRequest extends SharedAppointmentRequest {
  appointment?: AppointmentModel
  products?: ProductModel[]
  shoppableService?: ShoppableServiceModel
  schedulingQuestions?: SchedulingQuestionResponse[]

  constructor(data?: AppointmentRequestData) {
    super(data)

    if (data) {
      if (data.status === APPOINTMENT_REQUEST_STATUS.NEW || data.status === APPOINTMENT_REQUEST_STATUS.PATIENT_CONTACTED) {
        this.requestedDateTimes = undefined
      }

      if (data.products) {
        this.products = data.products.map(productData => new ProductModel(productData))
        this.productIds = data.productIds ?? this.products.map(product => product.id)
      }

      if (data.appointment) {
        this.appointment = new AppointmentModel(data.appointment)
        this.appointment.location = this.location
        this.appointment.timezone = this.location?.timezone
      }

      if (data.shoppableService) {
        this.shoppableService = new ShoppableServiceModel(data.shoppableService)
      }

      if (data.schedulingQuestions) {
        this.schedulingQuestions = data.schedulingQuestions
      }
    }
  }

  get url(): string {
    return `/patient/${this.patientId ?? this.patient.id}/appointment-requests/${this.id}`
  }
}

export { APPOINTMENT_REQUEST_TYPE } from '@mmx/shared'
